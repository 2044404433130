import React, { useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { adminAction } from '../../../redux/common/action'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// modal
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { URL } from '../../../redux/common/url';

// image uploader
import ImageUploading from 'react-images-uploading'
import { toast } from 'react-toastify'

import { AsyncPaginate } from 'react-select-async-paginate'


const sponserSchema = Yup.object().shape({
    banner_image: Yup.string(),
    link: Yup.string(),
    show: Yup.boolean().default(false),
    extra: Yup.string().default(''),
    clickCount: Yup.number(),
    viewCount: Yup.number(),
})

const initialValues = {
    link: '',
    show: false,
    extra: '',
}

interface Option {
    value: string
    label: string
}

interface PaginatedResponse {
    results: Option[]
    has_more: boolean
}

function NativeSponser() {
    const [sortingField, setSortingField] = useState('')
    const [sortingOrder, setSortingOrder] = useState('asc')
    // const [comments, setComments] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(1)

    const [itemsPerPage, setitemsPerPage] = useState(10)

    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState({ field: '', order: '' })

    // data table  state end

    // modal

    const [show, setShow] = useState(false)
    const [showeditPro, setShoweditPro] = useState(false)
    const [addpermission, setaddpermission] = useState(false)
    const [showDelelt, setShowDelete] = useState(false)

    const handleClose = () => setShow(false)
    const handleCloseeditPro = () => setShoweditPro(false)
    const handleCloseaddpermission = () => setaddpermission(false)
    const handleCloseDelete = () => setShowDelete(false)
    const handleShow = () => setShow(true)

    // modal end

    // api call

    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false)
    const [roleID, setroleID] = useState<any>('')
    const [productID, setproductID] = useState<any>('')
    const [productseID, setproductseID] = useState<any>('')
    const [pageNo, setpageNo] = useState<any>(1)

    // get api data

    // image uploder

    const [images, setImages] = React.useState<any>([])
    const maxNumber = 1;
    //text box states
    const [editData, seteditData] = React.useState<any>({});
    const onChange = (imageList: any, addUpdateIndex: any) => {
        setImages(imageList)
    }

    const get_product_list: any = useSelector((state: any) =>
        state.admin.get_banner_sponser ? state.admin.get_banner_sponser : []
    )


    // useEffect(() => {
    //     const dropList = get_search_tags_list?.map((data: any, i: any) => {
    //         data.value = data?._id
    //         data.label = data?.tags

    //         return data
    //     })

    //     setsubListtags(dropList)
    // }, [get_search_tags_list])


    const ITEMS_PER_PAGE = 30

    const hendleToaddPer = (e: any) => {
        setroleID(e)
        setaddpermission(true)
    }

    const hendleModalShow = (e: any) => {
        setShowDelete(true)
        setproductID(e)
    }



    const comments = get_product_list ? get_product_list : []
    console.log("get_product_list: ", get_product_list)
    const headers = [
        { name: 'S.No.', field: '_id', sortable: true },
        { name: 'Img', field: 'bannerImg', sortable: false },
        { name: 'Link', field: 'link', sortable: false },
        { name: 'show', field: 'show', sortable: false },
        { name: 'Click Count', field: 'clickCount', sortable: true },
        { name: 'View Count', field: 'viewCount', sortable: true },
        { name: 'Extra', field: 'extra', sortable: false },
        { name: 'Action', field: 'action', sortable: false },
    ]

    const commentsData = useMemo(() => {
        let computedComments = comments


        setTotalItems(computedComments.length)

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === 'asc' ? 1 : -1
            computedComments = computedComments.sort(
                (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            )
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        )
    }, [get_product_list, currentPage, search, sorting])

    console.log("useMero", commentsData)


    const handlePageClick = (event: any) => {

        const data = event?.selected + 1

        setpageNo(data)

        if (valueserisedata == null) {
            dispatch(adminAction.getBannerSponsersList({ pageNumber: data }))

            return () => { }
        } else {
            dispatch(
                adminAction.getBannerSponsersList({ pageNumber: data, series_id: valueserisedata?.id })
            )
            return () => { }
        }

    }

    const hendleSeacrhPro = (e: any) => {
        try {
            // searchProduct
            setproductseID(e)
            dispatch(adminAction.searchProduct({ key: e, page: 1 }))
            // dispatch(adminAction.getRoleList('dhsg'))
            return () => { }
        } catch (error) {
            console.log(error)
        }
    }
    const hendleChangeedit = (e: any) => {
        const { name, value } = e.target       
        seteditData({ ...editData, [name]: value })
    }
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage
        console.log("UseEffect RUnned:", get_product_list)
        setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
    }, [itemOffset, itemsPerPage, get_product_list])

    // Table shoorting Function

    const onSortingChange = (field: any) => {
        const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

        setSortingField(field)
        setSortingOrder(order)
        setSorting({ field, order })
    }


    const submitProduct = () => {
        try {

            const data = new FormData()

            setLoading(true)

            data.append('bannerImg', images[0]?.file)
            for (let field in editData) {
                data.append(field, editData[field])
            }
            dispatch(adminAction.addBannerSponser(data))
            console.log("data: ", data)
            setShow(false)
            setLoading(false)

            setTimeout(() => {
                dispatch(
                    adminAction.getBannerSponsersList({ pageNumber: 1, series_id: valueserisedata?.id })
                )
                return () => { }
            }, 500)
        } catch (error) {

        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: sponserSchema,

        onSubmit: async (values, { setStatus, setSubmitting }) => {

            setLoading(true)
            try {
                console.log("values :", values)
                const data = new FormData()
                // data.append('title', values?.role_name)
                // data.append('tegs', values?.role_description)
                // data.append('description', values?.description)
                // data.append('categoryName', 'test cate')
                // data.append('categoryId', '11')
                // data.append('sellerId', '20')

                dispatch(adminAction.addBannerSponser(data))
                setShow(false)
                setLoading(false)
            } catch (error) {

            }
        },
    })



    useEffect(() => {

        dispatch(adminAction.getBannerSponsersList({ pageNumber: 1 }))

        return () => { }
    }, [])

    const [valueserisedata, setValueserisedata] = useState<any>(null)

    console.log(valueserisedata)

    useEffect(() => {

        if (valueserisedata == null) {
        } else {
            dispatch(
                adminAction.getBannerSponsersList({ pageNumber: 1, series_id: valueserisedata?.id })
            )
            return () => { }
        }

    }, [valueserisedata])



    const hendleDelete = () => {
        setShowDelete(false)
        dispatch(adminAction.deleteBannerSponser({ id: productID }))

        return () => { }
    }

    const [Imagesfe, setImagesfe] = React.useState<any>([])
    const [editProDetal, seteditProDetal] = React.useState<any>({
        link: '',
        show: false,
        extra: '',
    })

    const onChangefe = (imageList: any, addUpdateIndex: any) => {
        setImagesfe(imageList)
    }

    const onChangeDataP = (e: any) => {
        const { name, value } = e.target
        seteditProDetal({ ...editProDetal, [name]: value })
    }


    const hendleeditProductBox = (e: any) => {
        //This function add data to edit form
        setShoweditPro(true)
        
        seteditProDetal({
            id:e.id,
            link: e.link,
            show: e.show ? 'Yes' : 'No',
            extra: e.extra,
            clickCount:e.clickCount,
            viewCount:e.viewCount
        })

        setImagesfe([{ data_url:  e.bannerImg }])

    }

    const hendleUpdate = () => {
        const data = new FormData()
      
            if (Imagesfe[0]?.file) {
                data.append('bannerImg', Imagesfe[0]?.file)
            }
            data.append('id',editProDetal?.id)
            data.append('link', editProDetal?.link)
            data.append('extra', editProDetal?.extra)
            data.append('show', editProDetal?.show)
            data.append('clickCount', editProDetal?.clickCount)
            data.append('viewCount', editProDetal?.viewCount)

            dispatch(adminAction.updateBannerSponser(data))

            setShoweditPro(false)
    }

    //? THis function has to do something with pagination
    
    const loadOptions = async (search: any, loadedOptions: any, { page }: { page: any }) => {
        const response = await fetch(
            URL.API_BASE_URL + `/apiPublic/getTeamListsearch2?search=${search}&page=${page}`
        )
        const responseJSON: PaginatedResponse = await response.json()

        const options: any = responseJSON?.results?.map((data: any) => {
            data.value = data?.id
            data.label = data?.name

            return data
        })

        return {
            options: options,
            hasMore: responseJSON.has_more,
            additional: {
                page: page + 1,
            },
        }
    }

    return (
        <div>
            <div className='d-flex flex-column flex-column-fluid'>
                <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
                    <div
                        id='kt_app_toolbar_container'
                        className='app-container container-xxl d-flex flex-stack'
                    >
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Banner Sponser</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Image</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <ImageUploading
                                        // multiple
                                        value={images}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                        dataURLKey='data_url'
                                    // acceptType={['jpg']}
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }) => (
                                            // write your building UI
                                            <div className='upload__image-wrapper'>
                                                <button
                                                    style={isDragging ? { color: 'red' } : {}}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                    className='btn btn-secondary'
                                                >
                                                    Add image
                                                </button>
                                                &nbsp;
                                                <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                                                    Remove  image
                                                </button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className='image-item'>
                                                        <img src={image.data_url} alt='' width='100' />
                                                        <div className='image-item__btn-wrapper'>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageUpdate(index)}
                                                            >
                                                                Update
                                                            </button>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageRemove(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Link</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "8%", marginLeft: "5px" }} type="text" value={editData.link} onChange={hendleChangeedit} name='link' placeholder='Link' />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Show</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <select
                                        className='form-control form-control-solid'
                                        name='show' onChange={hendleChangeedit} value={editData['show']}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Extra</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.extra} name='extra' placeholder='Extra' onChange={hendleChangeedit} />
                                </div>
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        className='btn btn-light me-3'
                                        data-kt-permissions-modal-action='cancel'
                                        onClick={handleClose}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            submitProduct()
                                        }}
                                        data-kt-permissions-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {/* </form> */}
                            </Modal.Body>
                        </Modal>

                        {/* update */}
                        <Modal show={showeditPro} onHide={handleCloseeditPro}>
                            <Modal.Header closeButton>
                                <Modal.Title>Banner Sponser</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Image</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <ImageUploading
                                        // multiple
                                        value={Imagesfe}
                                        onChange={onChangefe}
                                        maxNumber={maxNumber}
                                        dataURLKey='data_url'
                                    // acceptType={['jpg']}
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }) => (
                                            // write your building UI
                                            <div className='upload__image-wrapper'>
                                                <button
                                                    style={isDragging ? { color: 'red' } : {}}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                    className='btn btn-secondary'
                                                >
                                                    Choose image
                                                </button>
                                                &nbsp;
                                                <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                                                    Remove all images
                                                </button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className='image-item'>
                                                        <img src={image.data_url} alt='' width='100' />
                                                        <div className='image-item__btn-wrapper'>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageUpdate(index)}
                                                            >
                                                                Update
                                                            </button>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageRemove(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Link</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "8%", marginLeft: "5px" }} type="text" value={editProDetal.link} onChange={onChangeDataP} name='link' placeholder='Link' />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Show</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <select
                                        className='form-control form-control-solid'
                                        name='show' onChange={onChangeDataP} value={editProDetal['show']}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Extra</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.extra} name='extra' placeholder='Extra' onChange={onChangeDataP} />
                                </div>
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        className='btn btn-light me-3'
                                        data-kt-permissions-modal-action='cancel'
                                        onClick={handleCloseeditPro}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            hendleUpdate()
                                        }}
                                        data-kt-permissions-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {/* </form> */}
                            </Modal.Body>
                        </Modal>

                        {/* View modal */}
                        <Modal show={addpermission} onHide={handleCloseaddpermission}>
                            <Modal.Header closeButton>
                                <Modal.Title>Blog Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Woohoo, you're reading this text in a modal! */}

                                <div className='mb-10 d-flex'>
                                    <div>
                                        <h6>Feature Image</h6>
                                        <div className='productDetailsImg'>
                                            <img src={URL.API_BASE_URL + roleID?.feature_image} alt='' />
                                        </div>
                                        <div>
                                            <h6>Title</h6>
                                            <p>{roleID?.title}</p>
                                            <h6>short description</h6>
                                            <p>{roleID?.shortdiscription}</p>
                                            <h6>metatag</h6>
                                            <p>{roleID?.metatag}</p>
                                            <h6>metadiscription</h6>
                                            <p>{roleID?.metadiscription}</p>
                                            <h6>Description</h6>
                                            {/* <p>{roleID?.description}</p> */}
                                            <div
                                                className='bolgBox'
                                                dangerouslySetInnerHTML={{ __html: roleID?.description }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
                        </Modal>

                        {/* delete modal  */}
                        <Modal show={showDelelt} onHide={handleCloseDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title> Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='secondary' onClick={handleCloseDelete}>
                                    No
                                </Button>
                                <Button variant='primary' onClick={hendleDelete}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* <!--begin::Page title-->  */}
                        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                            {/* <!--begin::Title-->  */}
                            <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                                Banner Sponser
                            </h1>
                            {/* <!--end::Title-->  */}
                            {/* <!--begin::Breadcrumb-->  */}
                            <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                                {/* <!--begin::Item-->  */}
                                <li className='breadcrumb-item text-muted'>
                                    {/* <a href='../../demo1/dist/index.html' className='text-muted text-hover-primary'>
                    Home
                  </a> */}
                                    <Link to={'/'} className='text-muted text-hover-primary'>
                                        Home
                                    </Link>
                                </li>
                                {/* <!--end::Item-->  */}
                                {/* <!--begin::Item-->  */}
                                <li className='breadcrumb-item'>
                                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                                </li>
                                {/* <!--end::Item-->  */}
                                {/* <!--begin::Item-->  */}
                                <li className='breadcrumb-item text-muted'>Banner Sponser</li>
                                {/* <!--end::Item-->  */}
                            </ul>
                            {/* <!--end::Breadcrumb-->  */}
                        </div>
                        {/* <!--end::Page title-->  */}
                        {/* <!--begin::Actions-->  */}
                        <div className='d-flex align-items-center gap-2 gap-lg-3'>
                            {/* <!--begin::Filter menu-->  */}
                            <div className='m-0'>
                                {/* <!--begin::Menu toggle-->  */}

                                {/* <!--end::Menu toggle-->  */}
                                {/* <!--begin::Menu 1-->  */}
                                <div
                                    className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                                    data-kt-menu='true'
                                    id='kt_menu_637dc7729cedd'
                                >
                                    {/* <!--begin::Header-->  */}
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                                    </div>
                                    {/* <!--end::Header-->  */}
                                    {/* <!--begin::Menu separator-->  */}
                                    <div className='separator border-gray-200'></div>
                                    {/* <!--end::Menu separator-->  */}
                                    {/* <!--begin::Form-->  */}
                                    <div className='px-7 py-5'>
                                        {/* <!--begin::Input group-->  */}
                                        <div className='mb-10'>
                                            {/* <!--begin::Label-->  */}
                                            <label className='form-label fw-semibold'>Status:</label>
                                            {/* <!--end::Label-->  */}
                                            {/* <!--begin::Input-->  */}
                                            <div>
                                                <select
                                                    className='form-select form-select-solid'
                                                    data-kt-select2='true'
                                                    data-placeholder='Select option'
                                                    data-dropdown-parent='#kt_menu_637dc7729cedd'
                                                    data-allow-clear='true'
                                                >
                                                    <option></option>
                                                    <option value='1'>Approved</option>
                                                    <option value='2'>Pending</option>
                                                    <option value='2'>In Process</option>
                                                    <option value='2'>Rejected</option>
                                                </select>
                                            </div>
                                            {/* <!--end::Input-->  */}
                                        </div>
                                        {/* <!--end::Input group-->  */}
                                        {/* <!--begin::Input group-->  */}
                                        <div className='mb-10'>
                                            {/* <!--begin::Label-->  */}
                                            <label className='form-label fw-semibold'>Member Type:</label>
                                            {/* <!--end::Label-->  */}
                                            {/* <!--begin::Options-->  */}
                                            <div className='d-flex'>
                                                {/* <!--begin::Options-->  */}
                                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                                    <input className='form-check-input' type='checkbox' value='1' />
                                                    <span className='form-check-label'>Author</span>
                                                </label>
                                                {/* <!--end::Options-->  */}
                                                {/* <!--begin::Options-->  */}
                                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value='2'
                                                    //   checked='checked'
                                                    />
                                                    <span className='form-check-label'>Customer</span>
                                                </label>
                                                {/* <!--end::Options-->  */}
                                            </div>
                                            {/* <!--end::Options-->  */}
                                        </div>
                                        {/* <!--end::Input group-->  */}
                                        {/* <!--begin::Input group-->  */}
                                        <div className='mb-10'>
                                            {/* <!--begin::Label-->  */}
                                            <label className='form-label fw-semibold'>Notifications:</label>
                                            {/* <!--end::Label-->  */}
                                            {/* <!--begin::Switch-->  */}
                                            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value=''
                                                    name='notifications'
                                                // checked='checked'
                                                />
                                                <label className='form-check-label'>Enabled</label>
                                            </div>
                                            {/* <!--end::Switch-->  */}
                                        </div>
                                        {/* <!--end::Input group-->  */}
                                        {/* <!--begin::Actions-->  */}
                                        <div className='d-flex justify-content-end'>
                                            <button
                                                type='reset'
                                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                                data-kt-menu-dismiss='true'
                                            >
                                                Reset
                                            </button>
                                            <button
                                                type='submit'
                                                className='btn btn-sm btn-primary'
                                                data-kt-menu-dismiss='true'
                                            >
                                                Apply
                                            </button>
                                        </div>
                                        {/* <!--end::Actions-->  */}
                                    </div>
                                    {/* <!--end::Form-->  */}
                                </div>
                                {/* <!--end::Menu 1-->  */}
                            </div>
                            {/* <!--end::Filter menu-->  */}
                            {/* <!--begin::Secondary button-->  */}
                            {/* <!--end::Secondary button-->  */}
                            {/* <!--begin::Primary button-->  */}

                            {/* <!--end::Primary button-->  */}
                        </div>
                        {/* <!--end::Actions-->  */}
                    </div>
                    {/* <!--end::Toolbar container-->  */}
                </div>
                {/* <!--end::Toolbar-->  */}
                {/* <!--begin::Content-->  */}
                <div id='kt_app_content' className='app-content flex-column-fluid'>
                    {/* <!--begin::Content container-->  */}
                    <div id='kt_app_content_container' className='app-container container-xxl'>
                        {/* <!--begin::Card-->  */}
                        <div className='card card-flush'>
                            {/* <!--begin::Card header-->  */}
                            <div className='card-header mt-6'>
                                {/* <!--begin::Card title-->  */}
                                <div className='card-title'>
                                    {/* <!--begin::Search-->  */}
                                    <div className='d-flex align-items-center position-relative my-1 me-5'>
                                        {/* <AsyncPaginate<any, any, any>
                                            value={valueserisedata}
                                            loadOptions={loadOptionsSerires}
                                            onChange={setValueserisedata}
                                            additional={{
                                                page: 1,
                                            }}
                                        /> */}
                                    </div>
                                    {/* <!--end::Search-->  */}
                                </div>
                                {/* <!--end::Card title-->  */}
                                {/* <!--begin::Card toolbar-->  */}
                                <div className='card-toolbar'>
                                    {/* <!--begin::Button-->  */}
                                    <button
                                        type='button'
                                        className='btn btn-light-primary'
                                        onClick={handleShow}
                                    >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->  */}
                                        <span className='svg-icon svg-icon-3'>
                                            <svg
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <rect
                                                    opacity='0.3'
                                                    x='2'
                                                    y='2'
                                                    width='20'
                                                    height='20'
                                                    rx='5'
                                                    fill='currentColor'
                                                />
                                                <rect
                                                    x='10.8891'
                                                    y='17.8033'
                                                    width='12'
                                                    height='2'
                                                    rx='1'
                                                    transform='rotate(-90 10.8891 17.8033)'
                                                    fill='currentColor'
                                                />
                                                <rect
                                                    x='6.01041'
                                                    y='10.9247'
                                                    width='12'
                                                    height='2'
                                                    rx='1'
                                                    fill='currentColor'
                                                />
                                            </svg>
                                        </span>
                                        {/* <!--end::Svg Icon-->  */}Add Banner Sponser
                                    </button>
                                    {/* <!--end::Button-->  */}
                                </div>
                                {/* <!--end::Card toolbar-->  */}
                            </div>
                            {/* <!--end::Card header-->  */}
                            {/* <!--begin::Card body-->  */}
                            <div className='card-body pt-0'>
                                {/* <!--begin::Table-->  */}
                                <table
                                    className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                                    id='kt_permissions_table'
                                >
                                    {/* <!--begin::Table head-->  */}
                                    <thead>
                                        <tr>
                                            {headers.map((data: any, i: any) => (
                                                <th
                                                    key={data?.name}
                                                    onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                                                >
                                                    {data?.name}

                                                    {sortingField &&
                                                        sortingField === data?.field &&
                                                        (sortingOrder === 'asc' ? (
                                                            //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                                                            <i className='fa-solid fa-arrow-down'></i>
                                                        ) : (
                                                            <i className='fas fa-arrow-alt-up'></i>
                                                        ))}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                        {commentsData?.map((data: any, i: any) => {
                                            const So = 1 + i

                                            const pgt1 = pageNo - 1

                                            const pgt = pgt1 * 10

                                            const soNo = pgt + So
                                            return (
                                                <tr>
                                                    <td>
                                                        {soNo}
                                                    </td>

                                                    <td>
                                                        <div style={{ width: '100px' }}>
                                                            <img
                                                                style={{ width: '100%' }}
                                                                src={ data.bannerImg}
                                                                alt=''
                                                            />
                                                        </div>


                                                    </td>
                                                    <td>{data.link}</td>
                                                    <td>{data.show ? 'Yes' : 'No'}</td>
                                                    <td>{data.clickCount}</td>
                                                    <td>{data.clickCount}</td>

                                                    <td>{data.extra}</td>


                                                    <td>
                                                        <button
                                                            data-id='2209'
                                                            onClick={() => {
                                                                hendleeditProductBox(data)
                                                            }}
                                                            className='btn btn-sm btn-info ms-3'
                                                        >
                                                            <i className='fas fa-edit'></i>
                                                        </button>
                                                        <button
                                                            data-id='2209'
                                                            onClick={() => {
                                                                hendleModalShow(data?.id)
                                                            }}
                                                            className='btn btn-sm btn-danger ms-3'
                                                        >
                                                            <i className='fas fa-trash-alt'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    {get_product_list?.length === 0 && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '400px',
                                            }}
                                        >
                                            <h3>Record Not found</h3>
                                        </div>
                                    )}
                                    {/* <!--end::Table body-->  */}
                                </table>

                                <ReactPaginate
                                    breakLabel='...'
                                    nextLabel='next'
                                    className='dataTables_paginate paging_simple_numbers category'
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel='previous'
                                // renderOnZeroPageCount={null}
                                />
                            </div>

                            {/* <!--end::Card body-->  */}
                        </div>

                    </div>
                    {/* <!--end::Content container-->  */}
                </div>
                {/* <!--end::Content-->  */}
            </div>
            {/* <!--end::Content wrapper-->  */}
            {/* <!--begin::Footer-->  */}

            {/* <!--end::Footer-->  */}
            {/* </div> */}
        </div>
    )
}

export default NativeSponser;
