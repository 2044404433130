import { 
  LOADING,

  GET_PERMISSION_LISTS,
  GET_ROLES_LISTS,
  GET_PRODUCT_LISTS,
  GET_CATEGORY_LISTS,
  GET_SELLER_LISTS,
  GET_SUB_SUB_CATEGORY_LISTS,
  GET_SELLER_LISTS_PRO,
  GET_CATEGORY_ALL_LISTS,
  GET_SUB_SUB_CATE_LISTS,
  GET_SUB_CATEGORY_LISTS,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_PACKAGE_LISTS,
  GET_TRANSACTION_LISTS,
  GET_TICKET_LISTS,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_SELLER_PROFILE,
  GET_PAGES,
  GET_WHATSAPP_QU,
  GET_SUBSCRIPTION,
  GET_ADMIN_PROFILE,
  GET_INVOICE_DETAILS,
  GET_PACKAGE_LISTS_BUY,
  GET_EMAIL_QU,
  CREATE_TAGS,
  SEARCH_TAGS,
  FOOTER_PAGE_TYPE,
  FOOTER_PAGE_LIST,
  FOOTER_PAGE_LIST_SELECT,
  ANALYTICS_PRO_DATE,
  TOP_VIEW_PRO,
  TOP_VIEW_PRO_BY_CATE,
  TOP_VIEW_PRO_BY_SELLER,
  TOP_VIEW_ALL_TYPE,
  CREATE_SLIDER,
  CREATE_BANNERS,
  BLOG,
  HOME_POP,
  FAQS_LIST,
  LOGO_AND_OTHER,
  GET_TEAMS_LIST_FOR_WINNER_SELECT,
  BATSMAN_LIST,
  PLEY_ELEVEN_LIST,
  BOLWERS_LIST,
  POINTS_TABLE_LIST,
  GET_FALLWICET,
  CREATE_NATIVE_SPONSER,
  GET_NATIVE_SPONSER ,
  CREATE_BANNER_SPONSER,
  GET_BANNER_SPONSER,
  CREATE_INITRIAL_SPONSER,
  GET_INITRIAL_SPONSER,
  GET_MESSAGE,
  CREATE_MESSAGE,
  CREATE_HELP,
  GET_HELP,
  DELETE_HELP,
  UPDATE_HELP,
  CREATE_NEWS,
  GET_NEWS,
  DELETE_NEWS,
  UPDATE_NEWS
} from "../common/constant";
 type stateType = {
  loading:boolean;
  get_per_list: any;
  get_role_list: any;
  get_product_list: any;
  get_package_list: any;
  get_category_list: any;
  get_transaction_list: any;
  get_ticket_list: any;
  get_sub_sub_category_list: any;
  get_sub_category_list: any;
  get_sub_sub_cate_list: any;
  get_category_all_list: any;
  get_sub_category_all_list: any;
  get_seller_list: any;
  get_seller_list_pro: any;
  get_invoice: any;
  get_invoice_details: any;
  get_package_list_buy: any;
  get_pages: any;
  get_tags_list: any;
  get_search_tags_list: any;
  get_admin_profile: any;
  get_subscription: any;
  get_seller_profile: any;
  get_message_list: any;
  get_whatsapp_qui: any;
  get_email_qui: any;
  counsellordetail: any;
  coachAndCounsellor: any;
  counsellorinfo: any;
  counsellordetailBySlug: any;
  footer_page_type: any;
  footer_page_list: any;
  footer_page_list_select: any;
  analitics_prodact_by_date: any;
  top_view_pro: any;
  top_view_pro_by_cate: any;
  create_Slider: any;
  create_banners: any;
  blog: any;
  home_pop: any;
  faqs_list: any;
  logo_other: any;
  get_teams_list_for_winner_select: any;
  batsman_list: any;
  bolwers_list: any;
  play_eleven_list: any;
  points_table_list: any;
  get_fallwicket: any;
  create_native_sponser: any;
  get_native_sponser: any;
  create_banner_sponser: any;
  get_banner_sponser: any;
  create_initrial_sponser: any;
  get_initrial_sponser: any;
  get_message:any,
  create_message:any,
  create_help:any,
  get_help:any,
  delete_help:any,
  update_help:any
  create_news:any,
  get_news:any,
  delete_news:any,
  update_news:any
}
const initialState : stateType = {
    loading:false,
    get_per_list: [],
    get_role_list: [],
    get_search_tags_list: [],
    get_message_list: [],
    get_product_list: {},
    get_package_list: {},
    get_package_list_buy: [],
    footer_page_type: [],
    footer_page_list: [],
    footer_page_list_select: [],
    get_category_list: {},
    get_sub_category_list: {},
    get_sub_sub_cate_list: {},
    get_transaction_list: {},
    get_ticket_list: {},
    get_invoice: {},
    get_pages: {},
    get_tags_list: {},
    get_subscription: {},
    get_seller_profile: {},
    get_whatsapp_qui: {},
    get_admin_profile: {},
    get_invoice_details: {},
    get_email_qui: {},
    get_sub_sub_category_list: [],
    get_category_all_list: [],
    get_sub_category_all_list: [],
    get_seller_list: {},
    blog: {},
    home_pop: {},
    faqs_list: {},
    logo_other: {},
    batsman_list: {},
    bolwers_list: {},
    play_eleven_list: {},
    points_table_list: {},
    get_fallwicket: {},
    get_seller_list_pro: [],
    counsellordetail: [],
    coachAndCounsellor: [],
    counsellorinfo: [],
    counsellordetailBySlug: [],
    analitics_prodact_by_date: [],
    top_view_pro: [],
    top_view_pro_by_cate: [],
    create_Slider: [],
    create_banners: [],
    get_teams_list_for_winner_select: [],
    create_native_sponser:[],
    get_native_sponser:[],
    create_banner_sponser:[],
    get_banner_sponser:[],
    create_initrial_sponser:[],
    get_initrial_sponser:[],
    get_message:[],
    create_message:[],
    create_help:[],
    get_help:[],
    delete_help:[],
    update_help:[],
    create_news:[],
    get_news:[],
    delete_news:[],
    update_news:[],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_PERMISSION_LISTS:
      return { ...state, get_per_list: action.payload};
    case GET_ROLES_LISTS:
      return { ...state, get_role_list: action.payload};
    case GET_PRODUCT_LISTS:
      return { ...state, get_product_list: action.payload};
    case GET_CATEGORY_LISTS:
      return { ...state, get_category_list: action.payload};
    case GET_SELLER_LISTS:
      return { ...state, get_seller_list: action.payload};
    case GET_SUB_SUB_CATEGORY_LISTS:
      return { ...state, get_sub_sub_category_list: action.payload};
    case GET_SELLER_LISTS_PRO:
      return { ...state, get_seller_list_pro: action.payload};
    case GET_CATEGORY_ALL_LISTS:
      return { ...state, get_category_all_list: action.payload};
    case GET_SUB_CATEGORY_LISTS :
      return { ...state, get_sub_category_list: action.payload};
    case GET_SUB_SUB_CATE_LISTS:
      return { ...state, get_sub_sub_cate_list: action.payload};
    case GET_SUB_CATEGORY_ALL_LISTS:
      return { ...state, get_sub_category_all_list: action.payload};
    case GET_PACKAGE_LISTS:
      return { ...state, get_package_list: action.payload};
      
    case GET_TRANSACTION_LISTS:
      return { ...state, get_transaction_list: action.payload};
    case GET_TICKET_LISTS:
      return { ...state, get_ticket_list: action.payload};
    case GET_MESSAGE_LISTS:
      return { ...state, get_message_list: action.payload};
    case GET_INVOICE:
      return { ...state, get_invoice: action.payload};
    case GET_SELLER_PROFILE:
      return { ...state, get_seller_profile: action.payload};
    case GET_PAGES:
      return { ...state, get_pages: action.payload};
    case GET_WHATSAPP_QU:
      return { ...state, get_whatsapp_qui: action.payload};
    case GET_SUBSCRIPTION:
      return { ...state, get_subscription: action.payload};
    case GET_ADMIN_PROFILE:
      return { ...state, get_admin_profile: action.payload};
    case GET_INVOICE_DETAILS:
      return { ...state, get_invoice_details: action.payload};
    case GET_PACKAGE_LISTS_BUY:
      return { ...state, get_package_list_buy: action.payload};
    case GET_EMAIL_QU:
      return { ...state, get_email_qui: action.payload};
    case CREATE_TAGS:
      return { ...state, get_tags_list: action.payload};
    case SEARCH_TAGS:
      return { ...state, get_search_tags_list: action.payload};
    case FOOTER_PAGE_TYPE:
      return { ...state, footer_page_type: action.payload};
    case FOOTER_PAGE_LIST:
      return { ...state, footer_page_list: action.payload};
    case FOOTER_PAGE_LIST_SELECT:
      return { ...state, footer_page_list_select: action.payload};
    case ANALYTICS_PRO_DATE:
      return { ...state, analitics_prodact_by_date: action.payload};
    case TOP_VIEW_PRO:
      return { ...state, top_view_pro: action.payload};
    case TOP_VIEW_PRO_BY_CATE:
      return { ...state, top_view_pro_by_cate: action.payload};
    case TOP_VIEW_PRO_BY_SELLER:
      return { ...state, top_view_pro_by_seller: action.payload};
    case TOP_VIEW_ALL_TYPE:
      return { ...state, top_view_all_typer: action.payload};
    case CREATE_SLIDER:
      return { ...state, create_Slider: action.payload};
    case CREATE_BANNERS:
      return { ...state, create_banners: action.payload};
    case BLOG:
      return { ...state, blog: action.payload};
    case HOME_POP:
      return { ...state, home_pop: action.payload};
    case FAQS_LIST:
      return { ...state, faqs_list: action.payload};
    case LOGO_AND_OTHER:
      return { ...state, logo_other: action.payload};
      case GET_TEAMS_LIST_FOR_WINNER_SELECT:
        return { ...state, get_teams_list_for_winner_select: action.payload };
      case BATSMAN_LIST:
        return { ...state, batsman_list: action.payload };
      case BOLWERS_LIST:
        return { ...state, bolwers_list: action.payload };
      case POINTS_TABLE_LIST:
        return { ...state, points_table_list: action.payload };
      case PLEY_ELEVEN_LIST:
        return { ...state, play_eleven_list: action.payload };
      case GET_FALLWICET:
        return { ...state, get_fallwicket: action.payload };
    case CREATE_NATIVE_SPONSER:
      return { ...state, create_native_sponser: action.payload };
    case GET_NATIVE_SPONSER:
      return { ...state, get_native_sponser: action.payload };
    case CREATE_BANNER_SPONSER:
      return { ...state, create_banner_sponser: action.payload };
    case GET_BANNER_SPONSER:
      return { ...state, get_banner_sponser: action.payload }; 
    case CREATE_INITRIAL_SPONSER:
      return { ...state, create_initrial_sponser: action.payload };
    case GET_INITRIAL_SPONSER:
      return { ...state, get_initrial_sponser: action.payload };   
    case GET_MESSAGE:
        return { ...state, get_message: action.payload }; 
    case CREATE_MESSAGE:
      return { ...state, create_message: action.payload }; 
    case CREATE_HELP:
        return { ...state, create_help: action.payload };  
    case GET_HELP:
        return { ...state, get_help: action.payload };  
    case DELETE_HELP:
        return { ...state, delete_help: action.payload };  
    case UPDATE_HELP:
        return { ...state, update_help: action.payload };        
    case CREATE_NEWS:
        return { ...state, create_news: action.payload };  
    case GET_NEWS:
        return { ...state, get_news: action.payload };  
    case DELETE_NEWS:
        return { ...state, delete_news: action.payload };  
    case UPDATE_NEWS:
        return { ...state, update_news: action.payload };        
    default:
      return state;
  }
};