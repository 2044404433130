import React, { useState } from 'react';

const AuthorAutocomplete: React.FC<{ editData: any, hendleChangeedit: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({ editData, hendleChangeedit }) => {
  const [value, setValue] = useState<string>(editData.author || '');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const authorNames: string[] = [
    'Admin',
    'Aditi Srinivas',
    'Akshay Bhide',
    'Anil Kumar',
    'Ankit',
    'Arpit Sachdeva',
    'BL Choudhary',
    'Cricchamp cricket',
    'Deepak Kumar Panda',
    'Does Infotech',
    'Mohamed Farzan',
    'Jatin',
    'Sumit malgotra',
    'Nidhi Lohia',
    'Suhana Sumera',
    'Vaishnavi Iyer'
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    // Filter suggestions based on input value
    const filteredSuggestions = getSuggestions(value);
    setSuggestions(filteredSuggestions);
    // Call the parent component's handler to update state
    hendleChangeedit({ target: { name: 'author', value } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleSelectSuggestion = (suggestion: string) => {
    // Set the selected suggestion
    setSelectedValue(suggestion);
    // Keep the input value as the selected suggestion
    setValue(suggestion);
    // Clear the suggestions array
    setSuggestions([]);
    // Call the parent component's handler to update state
    hendleChangeedit({ target: { name: 'author', value: suggestion } } as React.ChangeEvent<HTMLInputElement>);
  };

  const getSuggestions = (inputValue: string): string[] => {
    // Filter suggestions based on input value
    return authorNames.filter(authorName =>
      authorName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <div>
      <input
        style={{ width: "90%", height: "10%", marginLeft: "5px" }}
        type="text"
        value={value}
        onChange={handleChange}
        placeholder='Type to search authors'
      />
      {/* Render suggestions */}
      <ul>
        {suggestions.map((suggestion, index) => (
          <li key={index} onClick={() => handleSelectSuggestion(suggestion)}>
            {suggestion}
          </li>
        ))}
      </ul>
      {/* Render selected value */}
      {<div>Selected Author: {value || selectedValue}</div>}
    </div>
  );
};

export default AuthorAutocomplete;
