/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../../redux/common/action'

type Props = {
  className: string
  type: any
  x: any
  y: any
}

const ChartsWidget9: React.FC<Props> = ({className, y, x, type}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const dispatch = useDispatch()

  const top_view_all_typer: any[] = useSelector((state: any) =>
    state.admin.top_view_all_typer ? state.admin.top_view_all_typer : []
  )


  const groupedData = top_view_all_typer.reduce((acc: any, current: any) => {
    if (!acc[current.year]) {
      acc[current.year] = {}
    }
    if (!acc[current.year][current.month]) {
      acc[current.year][current.month] = {}
    }
    if (!acc[current.year][current.month][current.type]) {
      acc[current.year][current.month][current.type] = 0
    }
    acc[current.year][current.month][current.type] += current.count
    return acc
  }, {})

  const groupedData2 = top_view_all_typer.reduce((acc, current) => {
    if (!acc[current.type]) {
      acc[current.type] = {}
    }
    // if (!acc[current.type]) {
    //   acc[current.type][current.year] = {}
    // }
    if (!acc[current.type][current.month]) {
      acc[current.type][current.month] = 0
    }
    acc[current.type][current.month] += current.count
    return acc
  }, {})
  const groupedData3 = top_view_all_typer.reduce((acc, current) => {
    if (!acc[current.type]) {
      acc[current.type] = {}
    }
    // if (!acc[current.type]) {
    //   acc[current.type][current.year] = {}
    // }
    if (!acc[current.type][current.day]) {
      acc[current.type][current.day] = 0
    }
    acc[current.type][current.day] += current.count
    return acc
  }, {})



  const types = [...(top_view_all_typer.map((item) => item.type) as any)]
  const yearsmain = [...(top_view_all_typer.map((item) => item.year) as any)]
  const monthsmain = [...(top_view_all_typer.map((item) => item.month) as any)]
  const daysmain = [...(top_view_all_typer.map((item) => item.day) as any)]


  const years = Array.from(new Set(yearsmain))
  const months = Array.from(new Set(monthsmain))
  const days = Array.from(new Set(daysmain))



  const [monthDay, setmonthDay] = useState<any>('12')
  const [monthDayMonth, setmonthDayMonth] = useState<any>('month')

  const series2 = Object.keys(groupedData2).map((type) => {
    return {
      name: type,
      data: months.map((month) => {
        if (groupedData2[type] && groupedData2[type] && groupedData2[type][month]) {
  

          return groupedData2[type][month]
        } else {
          return 0
        }
      }),
    }
  })
  const series6 = Object.keys(groupedData3).map((type) => {
    return {
      name: type,
      data: days.map((month) => {
        if (groupedData3[type] && groupedData3[type] && groupedData3[type][month]) {
   

          return groupedData3[type][month]
        } else {
          return 0
        }
      }),
    }
  })



  const series = types.map((type) => {
    return {
      name: type,
      data: months.map((month) => {
        return years.map((year) => {
          if (groupedData[year] && groupedData[year][month] && groupedData[year][month][type]) {
            return groupedData[year][month][type]
          } else {
            return 0
          }
        })
      }),
    }
  })

  //   series6
  // months

  console.log(monthDayMonth);
  console.log(months);
  

    const monthDays = monthDayMonth == 'month' ? months : days
    const seriesDat = monthDayMonth == 'month' ? series2 : series6

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, y, monthDays, seriesDat))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    console.log("sdfff");
    

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, top_view_all_typer, monthDays, seriesDat])

  // productYearWise



  const hendelYear = (e: any, a: any) => {


    setmonthDay(e)
    setmonthDayMonth(a)

    dispatch(adminAction.topViewAllType({duration: e, type: a}))

    return () => {}

    // dispatch(adminAction.productYearWise({}))

    // return () => {}
  }

  useEffect(() => {
    dispatch(adminAction.topViewAllType({duration: '12', type: 'month'}))

    return () => {}
  }, [])



  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{type}</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className={
              monthDay == '12'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('12', 'month')
            }}
          >
            last 12 month
          </a>
          <a
            className={
              monthDay == '15'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('15', 'day')
            }}
          >
            last 15 day
          </a>
          <a
            className={
              monthDay == '30'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('30', 'day')
            }}
          >
            last 30 day
          </a>
          <a
            className={
              monthDay == 'current'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('current', 'day')
            }}
          >
            current month
          </a>
          <a
            className={
              monthDay == 'previous'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('previous', 'day')
            }}
          >
            previous month
          </a>
          <a
            className={
              monthDay == '3'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('3', 'month')
            }}
          >
            last 3 month
          </a>
          <a
            className={
              monthDay == '6'
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            }
            id='kt_charts_widget_3_year_btn'
            onClick={() => {
              hendelYear('6', 'month')
            }}
          >
            last 6 month
          </a>
          {/* <a
            className={'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'}
            id='kt_charts_widget_3_year_btn'
            onClick={hendelYear}
          >
             last 15 day
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_week_btn'
          >
            Week
          </a> */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget9}

function getChartOptions(height: number, y: any, x: any, series: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  const lightColor = getCSSVariableValue('--kt-info-light')

  //   const baseColor = getCSSVariableValue('--kt-success')
  const baseLightColor = getCSSVariableValue('--kt-success-light')
  const secondaryColor = getCSSVariableValue('--kt-warning')
  const secondaryLightColor = getCSSVariableValue('--kt-warning-light')


  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      //   colors: [baseColor],
    },
    xaxis: {
      categories: x,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val
        },
      },
    },
    colors: ['red', secondaryColor, 'green'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      //   strokeColors: baseColor,
      colors: ['red', secondaryColor, 'green'],
      strokeColors: ['red', secondaryColor, 'green'],
      strokeWidth: 3,
    },
  }
}
