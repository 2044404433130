import React, { useEffect, useMemo, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { adminAction } from '../../../redux/common/action';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// modal
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { URL } from '../../../redux/common/url';

// image uploader
import ImageUploading from 'react-images-uploading'
import { toast } from 'react-toastify'
import { url } from 'inspector';
// Jodit Editor
import JoditEditor from 'jodit-react';
import AuthorAutocomplete from '../../modules/components/AuthorAutocomplete';

const Editor = (props: any) => {
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: props.placeholder || 'Description Full',
            enableDragAndDropFileToEditor: true,
            uploader: {
                insertImageAsBase64URI: false,
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                withCredentials: false,
                format: 'json',
                method: 'POST',
                url: URL.API_BASE_URL + '/apiPublic/news/image',
                prepareData: function (data: any) {
                    //@ts-ignore
                    data.append('file', this.file);
                    return data;
                },
                isSuccess: function (resp: any) {
                    // console.log("resp", resp)
                    return !resp.error;
                },
                getMsg: function (resp: any) {
                    return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
                },
                process: function (resp: any) {
                    // console.log(resp)
                    return {
                        files: [resp.data],
                        path: resp.path,
                        baseurl: resp.baseurl,
                        error: resp.error ? 1 : 0,
                        msg: resp.msg
                    };
                },
                defaultHandlerSuccess: function (data: any, resp: any) {
                    const files = data.files || [];

                    if (files.length) {
                        //@ts-ignore
                        this.selection.insertImage(data.baseurl, null, 250);
                    }
                },
                defaultHandlerError: function (resp: any) {
                    console.log("error occure", resp)
                    //@ts-ignore
                    this.events.fire('errorPopap', this.i18n(resp.msg));
                }
            }
        }),
        [props.placeholder]
    );

    function handleChange(value: any) {
        props.onChange(value)
    }

    return (
        <JoditEditor
            ref={editor}
            value={props.value}
            config={config}

            // tabIndex={1} // tabIndex of textarea
            // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => handleChange(newContent)}
        />
    );
};
//
const sponserSchema = Yup.object().shape({
    title: Yup.string().required(),
    short_desc: Yup.string().optional(),
    description_1: Yup.string().optional(),
    description_2: Yup.string().optional(),
    description_3: Yup.string().optional(),
    description_full: Yup.mixed().optional(),
    img: Yup.string().optional(),
    tags: Yup.string(),
    category: Yup.string(),
    credit_by: Yup.string().required(),
    author: Yup.string().required(),
    send_notification: Yup.string().required(),
})

const initialValues = {
    title: '',
    short_desc: '',
    description_1: '',
    description_2: '',
    description_3: '',
    description_full: '',
    tags: '',
    category: '',
    credit_by: '',
    author: 'admin',
    send_notification: "Yes",
}

interface Option {
    value: string
    label: string
}

interface PaginatedResponse {
    results: Option[]
    has_more: boolean
}

function News() {
    const [sortingField, setSortingField] = useState('')
    const [sortingOrder, setSortingOrder] = useState('asc')
    // const [comments, setComments] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(1)

    const [itemsPerPage, setitemsPerPage] = useState(10)

    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState({ field: '', order: '' })

    // data table  state end

    // modal

    const [show, setShow] = useState(false)
    const [showeditPro, setShoweditPro] = useState(false)
    const [addpermission, setaddpermission] = useState(false)
    const [showDelelt, setShowDelete] = useState(false)
    const [fullDesc, setfullDesc] = useState(false)

    const handleClose = () => setShow(false)
    const handleCloseeditPro = () => {
        setShoweditPro(false)
        setfullDesc(false)
    }
    const handleCloseaddpermission = () => setaddpermission(false)
    const handleCloseDelete = () => setShowDelete(false)
    const handleShow = () => setShow(true)

    // modal end

    // api call

    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false)
    const [roleID, setroleID] = useState<any>('')
    const [productID, setproductID] = useState<any>('')
    const [productseID, setproductseID] = useState<any>('')
    const [pageNo, setpageNo] = useState<any>(1)

    // get api data

    // image uploder

    const [images, setImages] = React.useState<any>([])
    const maxNumber = 1;
    //text box states
    const [editData, seteditData] = React.useState<any>({});
    const onChange = (imageList: any, addUpdateIndex: any) => {
        setImages(imageList)
    }

    const get_product_list: any = useSelector((state: any) =>
        state.admin.get_news ? state.admin.get_news : []
    )
    const ITEMS_PER_PAGE = 30

    const hendleModalShow = (e: any) => {
        setShowDelete(true)
        setproductID(e)
    }

    const comments = get_product_list ? get_product_list : []

    const headers = [
        { name: 'S.No.', field: '_id', sortable: true },
        { name: 'Title', field: 'title', sortable: true },
        { name: 'Category', field: 'category', sortable: true },
        { name: 'Short Desc', field: 'short_desc', sortable: true },
        { name: 'Description 1', field: 'description_1', sortable: true },
        { name: 'Description 2', field: 'description_2', sortable: true },
        { name: 'Description 3', field: 'description_3', sortable: true },
        { name: 'Description Full', field: 'description_full', sortable: true },
        { name: 'Image', field: 'image', sortable: false },
        { name: 'Tags', field: 'tags', sortable: false },
        { name: 'Credit By', field: 'credit_by', sortable: true },
        { name: 'Author', field: 'author', sortable: true },
        { name: 'Notify', field: 'send_notification', sortable: true },
        { name: 'Action', field: 'action', sortable: false },
    ]

    const commentsData = useMemo(() => {
        let computedComments = comments


        setTotalItems(computedComments.length)

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === 'asc' ? 1 : -1
            computedComments = computedComments.sort(
                (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            )
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        )
    }, [get_product_list, currentPage, search, sorting])

    const handlePageClick = (event: any) => {

        const data = event?.selected + 1

        setpageNo(data)

        if (valueserisedata == null) {
            dispatch(adminAction.getNewsList({ pageNumber: data }))

            return () => { }
        } else {
            dispatch(
                adminAction.getNewsList({ pageNumber: data, series_id: valueserisedata?.id })
            )
            return () => { }
        }

    }

    const hendleChangeedit = (e: any) => {
        let { name, value } = e.target
        // console.log(`${name}: `,value)
        seteditData({ ...editData, [name]: value })
    }
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage
        setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
    }, [itemOffset, itemsPerPage, get_product_list])

    // Table shoorting Function

    const onSortingChange = (field: any) => {
        const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

        setSortingField(field)
        setSortingOrder(order)
        setSorting({ field, order })
    }


    const submitProduct = (e: any) => {
        e.preventDefault()

        console.log(editData)
        try {

            const data = new FormData()

            setLoading(true)
            data.append('img', images[0]?.file)
            for (let field in editData) {
                if (field === "send_notification") {
                    data.append(field, ((editData[field] === 'Yes') ? true : false) as any)
                } else {
                    data.append(field, editData[field])
                }
            }

            dispatch(adminAction.addNews(data))
            setShow(false)
            setLoading(false)

            setTimeout(() => {
                dispatch(
                    adminAction.getNewsList({ pageNumber: 1, series_id: valueserisedata?.id })
                )
                return () => { }
            }, 500)
        } catch (error) {

        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: sponserSchema,

        onSubmit: async (values, { setStatus, setSubmitting }) => {

            setLoading(true)
            try {
                console.log("Formik values :", values)
                const data = new FormData()
                data.append('title', values?.title)
                data.append('tags', values?.tags)
                data.append('short_desc', values?.short_desc)
                data.append('description_1', values?.description_1)
                data.append('description_2', values?.description_2)
                data.append('description_3', values?.description_3)
                data.append('description_full', values?.description_full)
                data.append('category', values?.category)
                data.append('credit_by', values?.credit_by)
                data.append('author', values?.author)
                data.append('send_notification', values?.send_notification)

                dispatch(adminAction.addBannerSponser(data))
                setShow(false)
                setLoading(false)
            } catch (error) {

            }
        },
    })



    useEffect(() => {

        dispatch(adminAction.getNewsList({ pageNumber: 1 }))

        return () => { }
    }, [])

    const [valueserisedata, setValueserisedata] = useState<any>(null)

    // console.log(valueserisedata)

    useEffect(() => {

        if (valueserisedata == null) {
        } else {
            dispatch(
                adminAction.getNewsList({ pageNumber: 1, series_id: valueserisedata?.id })
            )
            return () => { }
        }

    }, [valueserisedata])



    const hendleDelete = () => {
        setShowDelete(false)
        dispatch(adminAction.deleteNews({ id: productID }))

        return () => { }
    }

    const [Imagesfe, setImagesfe] = React.useState<any>([])

    const [editProDetal, seteditProDetal] = React.useState<any>({
        // title: '',
        // category: '',
        // description: '',
        // text_image: '',
        // blog_type: '',
        // video_link: '',
    })

    const onChangefe = (imageList: any, addUpdateIndex: any) => {
        setImagesfe(imageList)
    }

    const onChangeDataP = (e: any) => {
        const { name, value } = e.target
        seteditProDetal({ ...editProDetal, [name]: value })
    }


    const hendleeditProductBox = (e: any) => {
        //This function add data to edit form
        setShoweditPro(true)
        if (e?.description_full!='<p><br></p>') {
            setfullDesc(true);
        }
        seteditProDetal({
            id: e.id,
            title: e.title,
            category: e.category,
            short_desc: e.short_desc,
            description_1: e.description_1,
            description_2: e.description_2,
            description_3: e.description_3,
            description_full: e.description_full,
            tags: e.tags.join(','),
            credit_by: e.credit_by,
            author: e.author,
            send_notification: e.send_notification ? "Yes" : "No",
        })
        setImagesfe([{ data_url: URL.API_BASE_URL + e.image }])
    }

    const hendleUpdate = () => {
        const data = new FormData()

        if (Imagesfe[0]?.file) {
            data.append('img', Imagesfe[0]?.file)
        }
        // data.append('id', editProDetal?.id)
        data.append('title', editProDetal?.title)
        data.append('tags', editProDetal?.tags)
        data.append('short_desc', editProDetal?.short_desc)
        data.append('description_1', editProDetal?.description_1)
        data.append('description_2', editProDetal?.description_2)
        data.append('description_3', editProDetal?.description_3)
        data.append('description_full', editProDetal?.description_full)
        data.append('category', editProDetal?.category)
        data.append('credit_by', editProDetal?.credit_by)
        data.append('author', editProDetal?.author)
        data.append('send_notification', editProDetal?.send_notification === "Yes" ? true : false as any)

        dispatch(adminAction.updateNews(data, editProDetal?.id))

        setShoweditPro(false)
    }

    function handleChange(value: any) {
        seteditData({ ...editData, description_full: value })
    }

    return (
        <div>
            <div className='d-flex flex-column flex-column-fluid' style={{ width: "100%" }}>
                <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
                    <div
                        id='kt_app_toolbar_container'
                        className='app-container container-xxl d-flex flex-stack'
                    >
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add News</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Title</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "8%", marginLeft: "5px" }} type="text" value={editData.title} onChange={hendleChangeedit} name='title' placeholder='Title' />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Category</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <select
                                        className='form-control form-control-solid'
                                        name='category' onChange={hendleChangeedit} value={editData['category']}>
                                        <option value="NEWS">News</option>
                                        <option value="ARTICLE">Article</option>
                                    </select>
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.category} name='category' placeholder='Category' onChange={hendleChangeedit} /> */}
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Short Desc.</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.short_desc} name='short_desc' placeholder='description' onChange={hendleChangeedit} />
                                </div>
                                <div className='fv-row mb-7'>
                                    {/* {data.show ? 'Yes' : 'No'} */}
                                    <span><b>Editor Mode</b></span>
                                    &nbsp;
                                    <label className="switch">
                                        <input type="checkbox" checked={fullDesc} onClick={() => setfullDesc(!fullDesc)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='fv-row mb-7' hidden={fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description 1</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.description_1} name='description_1' placeholder='Description' onChange={hendleChangeedit} />
                                </div>
                                <div className='fv-row mb-7' hidden={fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description 2</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.description_2} name='description_2' placeholder='Description' onChange={hendleChangeedit} />
                                </div>
                                <div className='fv-row mb-7' hidden={fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description 3</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.description_3} name='description_3' placeholder='Description' onChange={hendleChangeedit} />
                                </div>
                                <div className='fv-row mb-7' hidden={!fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description Full</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    {<Editor placeholder={"Description"}
                                        onChange={handleChange}
                                    ></Editor>}
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.description_full} name='description_full' placeholder='Description' onChange={hendleChangeedit} /> */}
                                </div>

                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Image</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <ImageUploading
                                        // multiple
                                        value={images}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                        dataURLKey='data_url'
                                    // acceptType={['jpg']}
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }) => (
                                            // write your building UI
                                            <div className='upload__image-wrapper'>
                                                <button
                                                    style={isDragging ? { color: 'red' } : {}}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                    className='btn btn-secondary'
                                                >
                                                    Add image
                                                </button>
                                                &nbsp;
                                                <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                                                    Remove  image
                                                </button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className='image-item'>
                                                        <img src={image.data_url} alt='' width='100' />
                                                        <div className='image-item__btn-wrapper'>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageUpdate(index)}
                                                            >
                                                                Update
                                                            </button>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageRemove(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Tags</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.tags} name='tags' placeholder='Tags' onChange={hendleChangeedit} />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Credit BY</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.credit_by} name='credit_by' placeholder='Credit By' onChange={hendleChangeedit} />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Author</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                        <AuthorAutocomplete editData={editData} hendleChangeedit={hendleChangeedit}/>
                                    </label>
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.author} name='author' placeholder='Author' onChange={hendleChangeedit} /> */}
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Notify</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <select
                                        className='form-control form-control-solid'
                                        name='send_notification' onChange={hendleChangeedit} value={editData['send_notification']}>
                                        <option value="Yes">Yes</option>
                                        <option value="No" >No</option>
                                    </select>
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.category} name='category' placeholder='Category' onChange={hendleChangeedit} /> */}
                                </div>
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        className='btn btn-light me-3'
                                        data-kt-permissions-modal-action='cancel'
                                        onClick={handleClose}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={(e) => {
                                            submitProduct(e)
                                        }}
                                        data-kt-permissions-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {/* </form> */}
                            </Modal.Body>
                        </Modal>

                        {/* update */}
                        <Modal show={showeditPro} onHide={handleCloseeditPro}>
                            <Modal.Header closeButton>
                                <Modal.Title>News</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Title</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "8%", marginLeft: "5px" }} type="text" value={editProDetal.title} onChange={onChangeDataP} name='title' placeholder='Title' />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Category</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <select
                                        className='form-control form-control-solid'
                                        name='category' onChange={onChangeDataP} value={editProDetal['category']}>
                                        <option value="NEWS">News</option>
                                        <option value="ARTICLE">Article</option>
                                    </select>
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.category} name='category' placeholder='Category' onChange={hendleChangeedit} /> */}
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Short Desc.</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.short_desc} name='short_desc' placeholder='Short Description' onChange={onChangeDataP} />
                                </div>
                                <div className='fv-row mb-7'>
                                    <span><b>Editor Mode</b></span>
                                    &nbsp;
                                    <label className="switch">
                                        <input type="checkbox" checked={fullDesc} onClick={() => setfullDesc(!fullDesc)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='fv-row mb-7' hidden={fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description 1</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.description_1} name='description_1' placeholder='Description' onChange={onChangeDataP} />
                                </div>
                                <div className='fv-row mb-7' hidden={fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description 2</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.description_2} name='description_2' placeholder='Description' onChange={onChangeDataP} />
                                </div>
                                <div className='fv-row mb-7' hidden={fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description 3</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.description_3} name='description_3' placeholder='Description' onChange={onChangeDataP} />
                                </div>
                                <div className='fv-row mb-7' hidden={!fullDesc}>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Description Full</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    {<Editor placeholder={"Description"}
                                        onChange={(value: any) => {
                                            seteditProDetal({ ...editProDetal, description_full: value })
                                        }}
                                        value={editProDetal.description_full}

                                    ></Editor>}
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.description_full} name='description_full' placeholder='Description' onChange={onChangeDataP} /> */}
                                </div>

                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Image</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <ImageUploading
                                        // multiple
                                        value={Imagesfe}
                                        onChange={onChangefe}
                                        maxNumber={maxNumber}
                                        dataURLKey='data_url'
                                    // acceptType={['jpg']}
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }) => (
                                            // write your building UI
                                            <div className='upload__image-wrapper'>
                                                <button
                                                    style={isDragging ? { color: 'red' } : {}}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                    className='btn btn-secondary'
                                                >
                                                    Choose image
                                                </button>
                                                &nbsp;
                                                <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                                                    Remove all images
                                                </button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className='image-item'>
                                                        <img src={image.data_url} alt='' width='100' />
                                                        <div className='image-item__btn-wrapper'>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageUpdate(index)}
                                                            >
                                                                Update
                                                            </button>
                                                            <button
                                                                className='btn btn-secondary'
                                                                onClick={() => onImageRemove(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Tags</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.tags} name='tags' placeholder='Tags' onChange={onChangeDataP} />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Credit BY</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.credit_by} name='credit_by' placeholder='Credit By' onChange={onChangeDataP} />
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span >Author</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <AuthorAutocomplete editData={editProDetal} hendleChangeedit={onChangeDataP}/>
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editProDetal.author} name='author' placeholder='Author' onChange={onChangeDataP} /> */}
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                        <span className='required'>Notify</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='popover'
                                            data-bs-trigger='hover'
                                            data-bs-html='true'
                                            data-bs-content='Permission names is required to be unique.'
                                        ></i>
                                    </label>
                                    <select
                                        className='form-control form-control-solid'
                                        name='send_notification' onChange={onChangeDataP} value={editProDetal['send_notification']}>
                                        <option value="Yes">Yes</option>
                                        <option value="No" >No</option>
                                    </select>
                                    {/* <input style={{ width: "70%", height: "10%", marginLeft: "5px" }} type="text" value={editData.category} name='category' placeholder='Category' onChange={hendleChangeedit} /> */}
                                </div>
                                {/* {Discard and submit btn} */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        className='btn btn-light me-3'
                                        data-kt-permissions-modal-action='cancel'
                                        onClick={handleCloseeditPro}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            hendleUpdate()
                                        }}
                                        data-kt-permissions-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {/* </form> */}
                            </Modal.Body>
                        </Modal>

                        {/* View modal */}
                        <Modal show={addpermission} onHide={handleCloseaddpermission}>
                            <Modal.Header closeButton>
                                <Modal.Title>News Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Woohoo, you're reading this text in a modal! */}

                                <div className='mb-10 d-flex'>
                                    <div>
                                        <h6>Feature Image</h6>
                                        <div className='productDetailsImg'>
                                            <img src={URL.API_BASE_URL + roleID?.feature_image} alt='' />
                                        </div>
                                        <div>
                                            <h6>Title</h6>
                                            <p>{roleID?.title}</p>
                                            <h6>short description</h6>
                                            <p>{roleID?.shortdiscription}</p>
                                            <h6>metatag</h6>
                                            <p>{roleID?.metatag}</p>
                                            <h6>metadiscription</h6>
                                            <p>{roleID?.metadiscription}</p>
                                            <h6>Description</h6>
                                            {/* <p>{roleID?.description}</p> */}
                                            <div
                                                className='bolgBox'
                                                dangerouslySetInnerHTML={{ __html: roleID?.description }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal>

                        {/* delete modal  */}
                        <Modal show={showDelelt} onHide={handleCloseDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title> Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='secondary' onClick={handleCloseDelete}>
                                    No
                                </Button>
                                <Button variant='primary' onClick={hendleDelete}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* <!--begin::Page title-->  */}
                        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                            {/* <!--begin::Title-->  */}
                            <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                                News
                            </h1>
                            {/* <!--end::Title-->  */}
                            {/* <!--begin::Breadcrumb-->  */}
                            <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                                {/* <!--begin::Item-->  */}
                                <li className='breadcrumb-item text-muted'>
                                    {/* <a href='../../demo1/dist/index.html' className='text-muted text-hover-primary'>
                    Home
                  </a> */}
                                    <Link to={'/'} className='text-muted text-hover-primary'>
                                        Home
                                    </Link>
                                </li>
                                {/* <!--end::Item-->  */}
                                {/* <!--begin::Item-->  */}
                                <li className='breadcrumb-item'>
                                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                                </li>
                                {/* <!--end::Item-->  */}
                                {/* <!--begin::Item-->  */}
                                <li className='breadcrumb-item text-muted'>News</li>
                                {/* <!--end::Item-->  */}
                            </ul>
                            {/* <!--end::Breadcrumb-->  */}
                        </div>
                        {/* <!--end::Page title-->  */}
                        {/* <!--begin::Actions-->  */}
                        <div className='d-flex align-items-center gap-2 gap-lg-3'>
                            {/* <!--begin::Filter menu-->  */}
                            <div className='m-0'>
                                {/* <!--begin::Menu toggle-->  */}

                                {/* <!--end::Menu toggle-->  */}
                                {/* <!--begin::Menu 1-->  */}
                                <div
                                    className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                                    data-kt-menu='true'
                                    id='kt_menu_637dc7729cedd'
                                >
                                    {/* <!--begin::Header-->  */}
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                                    </div>
                                    {/* <!--end::Header-->  */}
                                    {/* <!--begin::Menu separator-->  */}
                                    <div className='separator border-gray-200'></div>
                                    {/* <!--end::Menu separator-->  */}
                                    {/* <!--begin::Form-->  */}
                                    <div className='px-7 py-5'>
                                        {/* <!--begin::Input group-->  */}
                                        <div className='mb-10'>
                                            {/* <!--begin::Label-->  */}
                                            <label className='form-label fw-semibold'>Status:</label>
                                            {/* <!--end::Label-->  */}
                                            {/* <!--begin::Input-->  */}
                                            <div>
                                                <select
                                                    className='form-select form-select-solid'
                                                    data-kt-select2='true'
                                                    data-placeholder='Select option'
                                                    data-dropdown-parent='#kt_menu_637dc7729cedd'
                                                    data-allow-clear='true'
                                                >
                                                    <option></option>
                                                    <option value='1'>Approved</option>
                                                    <option value='2'>Pending</option>
                                                    <option value='2'>In Process</option>
                                                    <option value='2'>Rejected</option>
                                                </select>
                                            </div>
                                            {/* <!--end::Input-->  */}
                                        </div>
                                        {/* <!--end::Input group-->  */}
                                        {/* <!--begin::Input group-->  */}
                                        <div className='mb-10'>
                                            {/* <!--begin::Label-->  */}
                                            <label className='form-label fw-semibold'>Member Type:</label>
                                            {/* <!--end::Label-->  */}
                                            {/* <!--begin::Options-->  */}
                                            <div className='d-flex'>
                                                {/* <!--begin::Options-->  */}
                                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                                    <input className='form-check-input' type='checkbox' value='1' />
                                                    <span className='form-check-label'>Author</span>
                                                </label>
                                                {/* <!--end::Options-->  */}
                                                {/* <!--begin::Options-->  */}
                                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value='2'
                                                    //   checked='checked'
                                                    />
                                                    <span className='form-check-label'>Customer</span>
                                                </label>
                                                {/* <!--end::Options-->  */}
                                            </div>
                                            {/* <!--end::Options-->  */}
                                        </div>
                                        {/* <!--end::Input group-->  */}
                                        {/* <!--begin::Input group-->  */}
                                        <div className='mb-10'>
                                            {/* <!--begin::Label-->  */}
                                            <label className='form-label fw-semibold'>Notifications:</label>
                                            {/* <!--end::Label-->  */}
                                            {/* <!--begin::Switch-->  */}
                                            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value=''
                                                    name='notifications'
                                                // checked='checked'
                                                />
                                                <label className='form-check-label'>Enabled</label>
                                            </div>
                                            {/* <!--end::Switch-->  */}
                                        </div>
                                        {/* <!--end::Input group-->  */}
                                        {/* <!--begin::Actions-->  */}
                                        <div className='d-flex justify-content-end'>
                                            <button
                                                type='reset'
                                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                                data-kt-menu-dismiss='true'
                                            >
                                                Reset
                                            </button>
                                            <button
                                                type='submit'
                                                className='btn btn-sm btn-primary'
                                                data-kt-menu-dismiss='true'
                                            >
                                                Apply
                                            </button>
                                        </div>
                                        {/* <!--end::Actions-->  */}
                                    </div>
                                    {/* <!--end::Form-->  */}
                                </div>
                                {/* <!--end::Menu 1-->  */}
                            </div>
                            {/* <!--end::Filter menu-->  */}
                            {/* <!--begin::Secondary button-->  */}
                            {/* <!--end::Secondary button-->  */}
                            {/* <!--begin::Primary button-->  */}

                            {/* <!--end::Primary button-->  */}
                        </div>
                        {/* <!--end::Actions-->  */}
                    </div>
                    {/* <!--end::Toolbar container-->  */}
                </div>
                {/* <!--end::Toolbar-->  */}
                {/* <!--begin::Content-->  */}
                <div id='kt_app_content' className='app-content flex-column-fluid'>
                    {/* <!--begin::Content container-->  */}
                    <div id='kt_app_content_container' className='app-container container-xxl'>
                        {/* <!--begin::Card-->  */}
                        <div className='card card-flush'>
                            {/* <!--begin::Card header-->  */}
                            <div className='card-header mt-6'>
                                {/* <!--begin::Card title-->  */}
                                <div className='card-title'>
                                    {/* <!--begin::Search-->  */}
                                    <div className='d-flex align-items-center position-relative my-1 me-5'>
                                        {/* <AsyncPaginate<any, any, any>
                                            value={valueserisedata}
                                            loadOptions={loadOptionsSerires}
                                            onChange={setValueserisedata}
                                            additional={{
                                                page: 1,
                                            }}
                                        /> */}
                                    </div>
                                    {/* <!--end::Search-->  */}
                                </div>
                                {/* <!--end::Card title-->  */}
                                {/* <!--begin::Card toolbar-->  */}
                                <div className='card-toolbar'>
                                    {/* <!--begin::Button-->  */}
                                    <button
                                        type='button'
                                        className='btn btn-light-primary'
                                        onClick={handleShow}
                                    >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->  */}
                                        <span className='svg-icon svg-icon-3'>
                                            <svg
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <rect
                                                    opacity='0.3'
                                                    x='2'
                                                    y='2'
                                                    width='20'
                                                    height='20'
                                                    rx='5'
                                                    fill='currentColor'
                                                />
                                                <rect
                                                    x='10.8891'
                                                    y='17.8033'
                                                    width='12'
                                                    height='2'
                                                    rx='1'
                                                    transform='rotate(-90 10.8891 17.8033)'
                                                    fill='currentColor'
                                                />
                                                <rect
                                                    x='6.01041'
                                                    y='10.9247'
                                                    width='12'
                                                    height='2'
                                                    rx='1'
                                                    fill='currentColor'
                                                />
                                            </svg>
                                        </span>
                                        {/* <!--end::Svg Icon-->  */}Add News
                                    </button>
                                    {/* <!--end::Button-->  */}
                                </div>
                                {/* <!--end::Card toolbar-->  */}
                            </div>
                            {/* <!--end::Card header-->  */}
                            {/* <!--begin::Card body-->  */}
                            <div className='card-body pt-0'>
                                {/* <!--begin::Table-->  */}
                                <table
                                    className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                                    id='kt_permissions_table'
                                >
                                    {/* <!--begin::Table head-->  */}
                                    <thead>
                                        <tr>
                                            {headers.map((data: any, i: any) => (
                                                <th
                                                    key={data?.name}
                                                    onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                                                >
                                                    {data?.name}
                                                    {sortingField &&
                                                        sortingField === data?.field &&
                                                        (sortingOrder === 'asc' ? (
                                                            //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                                                            <i className='fa-solid fa-arrow-down'></i>
                                                        ) : (
                                                            <i className='fas fa-arrow-alt-up'></i>
                                                        ))}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                        {commentsData?.map((data: any, i: any) => {
                                            const So = 1 + i

                                            const pgt1 = pageNo - 1

                                            const pgt = pgt1 * 10

                                            const soNo = pgt + So
                                            return (
                                                <tr>
                                                    <td>
                                                        {soNo}
                                                    </td>
                                                    <td>{data.title}</td>
                                                    <td>{data.category}</td>
                                                    <td>{data.short_desc}</td>
                                                    <td>{data.description_1}</td>
                                                    <td>{data.description_2}</td>
                                                    <td>{data.description_3}</td>
                                                    <td>{data.description_full.toString().substring(0, 150)}</td>
                                                    <td style={{
                                                        textOverflow: "ellipsi",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        maxHeight: "50px",
                                                        wordWrap: "break-word"
                                                    }}>
                                                        <div style={{ width: '100px' }}>
                                                            <img
                                                                style={{ width: '100%' }}
                                                                src={URL.API_BASE_URL + data.image}
                                                                alt=''
                                                            />
                                                        </div>
                                                    </td>
                                                    {/* <td>{data.image}</td> */}
                                                    <td>{data.tags.join(',')}</td>
                                                    <td>{data.credit_by}</td>
                                                    <td>{data.author}</td>
                                                    <td>{data.send_notification ? "Yes" : "No"}</td>
                                                    <td>
                                                        <button
                                                            data-id='2209'
                                                            onClick={() => {
                                                                hendleeditProductBox(data)
                                                            }}
                                                            className='btn btn-sm btn-info ms-3'
                                                        >
                                                            <i className='fas fa-edit'></i>
                                                        </button>
                                                        <button
                                                            data-id='2209'
                                                            onClick={() => {
                                                                hendleModalShow(data?.id)
                                                            }}
                                                            className='btn btn-sm btn-danger ms-3'
                                                        >
                                                            <i className='fas fa-trash-alt'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    {get_product_list?.length === 0 && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '400px',
                                            }}
                                        >
                                            <h3>Record Not found</h3>
                                        </div>
                                    )}
                                    {/* <!--end::Table body-->  */}
                                </table>

                                <ReactPaginate
                                    breakLabel='...'
                                    nextLabel='next'
                                    className='dataTables_paginate paging_simple_numbers category'
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel='previous'
                                // renderOnZeroPageCount={null}
                                />
                            </div>

                            {/* <!--end::Card body-->  */}
                        </div>

                    </div>
                    {/* <!--end::Content container-->  */}
                </div>
                {/* <!--end::Content-->  */}
            </div>
            {/* <!--end::Content wrapper-->  */}
            {/* <!--begin::Footer-->  */}

            {/* <!--end::Footer-->  */}
            {/* </div> */}
        </div>
    )
}

export default News;
