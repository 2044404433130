import React, {useEffect, useMemo, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../redux/common/action'
import {Pagination, TableHeader} from '../Table'
import PaginationComponent from '../Table/Pagination/Pagination'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import Dropdown from 'react-bootstrap/Dropdown'

// modal
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import {URL} from '../../../redux/common/url'
import {KTSVG} from '../../../_metronic/helpers'

// image uploader
import ImageUploading from 'react-images-uploading'
import {toast} from 'react-toastify'
import moment from 'moment'
// import { Toast } from 'react-bootstrap'

import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import DatePicker from 'react-datepicker'

const loginSchema = Yup.object().shape({
  role_name: Yup.string()
    // .role_name('Wrong role_name format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('product is required'),
  role_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('tegs is required'),
})

interface Option {
  value: string
  label: string
}

interface PaginatedResponse {
  results: Option[]
  has_more: boolean
}

const initialValues = {
  role_name: '',
  role_description: '',
  description: '',
}

function Product() {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')
  // const [comments, setComments] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(1)

  const [itemsPerPage, setitemsPerPage] = useState(10)

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({field: '', order: ''})

  // deta table  state end

  // modal

  const [show, setShow] = useState(false)
  const [showeditPro, setShoweditPro] = useState(false)
  const [addpermission, setaddpermission] = useState(false)
  const [showDelelt, setShowDelete] = useState(false)

  const handleClose = () => setShow(false)
  const handleCloseeditPro = () => setShoweditPro(false)
  const handleCloseaddpermission = () => setaddpermission(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShow = () => setShow(true)

  // modal end

  // api call

  const dispatch = useDispatch()

  // const [roledata, setroledata] = useState({})
  const [roledata, setroledata] = useState<any>({
    role_name: '',
    role_description: '',
    description: '',
  })
  const [loading, setLoading] = useState(false)
  const [roleID, setroleID] = useState<any>('')
  const [productID, setproductID] = useState<any>('')
  const [categoryID, setcategoryID] = useState<any>('')
  const [sellerID, setsellerID] = useState<any>('')
  const [productseID, setproductseID] = useState<any>('')
  const [pageNo, setpageNo] = useState<any>(1)

  // get api data

  // image uploder

  const [images, setImages] = React.useState<any>([])

  const [subList, setsubList] = React.useState<any>([])
  const [subList1, setsubList1] = React.useState<any>([])
  const [subListtags, setsubListtags] = React.useState<any>([])

  const maxNumber = 1

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImages(imageList)
  }
  const [imagesss, setImagesss] = React.useState([])
  const maxNumberss = 4

  const onChangess = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesss(imageList)
  }

  const counsellor: any[] = useSelector((state: any) =>
    state.admin.get_per_list ? state.admin.get_per_list : []
  )
  const get_role_list: any[] = useSelector((state: any) =>
    state.admin.get_role_list ? state.admin.get_role_list : []
  )
  const get_product_list: any = useSelector((state: any) =>
    state.admin.get_product_list ? state.admin.get_product_list : {}
  )
  const get_sub_sub_category_list: any = useSelector((state: any) =>
    state.admin.get_sub_sub_category_list ? state.admin.get_sub_sub_category_list : []
  )
  const get_seller_list_pro: any = useSelector((state: any) =>
    state.admin.get_seller_list_pro ? state.admin.get_seller_list_pro : []
  )
  const get_search_tags_list: any = useSelector((state: any) =>
    state.admin.get_search_tags_list ? state.admin.get_search_tags_list : []
  )

  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedOption1, setSelectedOption1] = useState<any>(null)
  const [selectedOptiontags, setSelectedOptiontags] = useState<any>(null)

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data: any, i: any) => {
      data.value = data?._id
      data.label = data?.name

      return data
    })

    setsubList(dropList)
  }, [get_sub_sub_category_list])

  useEffect(() => {
    const dropList = get_search_tags_list?.map((data: any, i: any) => {
      data.value = data?._id
      data.label = data?.tags

      return data
    })

    setsubListtags(dropList)
  }, [get_search_tags_list])

  useEffect(() => {
    const dropList = get_seller_list_pro?.map((data: any, i: any) => {
      data.value = data?._id
      data.label = data?.fullName

      return data
    })

    setsubList1(dropList)
  }, [get_seller_list_pro])

  const ITEMS_PER_PAGE = 10

  const hendleToaddPer = async (e: any) => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    var urlencoded = new URLSearchParams()
    urlencoded.append('id', e)

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    }

    //  fetch(
    //       URL.API_BASE_URL + `/apiPublic/getTeamListsearch2?search=${search}&page=${page}`
    //     )
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getmatchInfoDetails`,
      requestOptions
    )

    const responseJSON: any = await response.json()
    setroleID(responseJSON?.data)
    setaddpermission(true)
  }

  const hendleModalShow = (e: any) => {
    setShowDelete(true)
    setproductID(e)
  }

  // useEffect(() => {
  //   const getData = () => {
  //     // showLoader();

  //     fetch('https://jsonplaceholder.typicode.com/comments')
  //       .then((response) => response.json())
  //       .then((json) => {
  //         // hideLoader();
  //         setComments(json)
  //         //   console.log(json)
  //       })
  //   }

  //   getData()
  // }, [])

  const comments = get_product_list?.docs ? get_product_list?.docs : []

  const headers = [
    {name: 'S.No.', field: '_id', sortable: false},
    {name: 'match_id', field: 'match_id', sortable: false},
    {name: 'matchs', field: 'matchs', sortable: false},
    {name: 'toss', field: 'toss', sortable: false},
    {name: 'Action', field: 'action', sortable: false},
  ]

  const commentsData = useMemo(() => {
    let computedComments = comments

    // if (search) {
    //     computedComments = computedComments.filter(
    //         comment =>
    //             comment.name.toLowerCase().includes(search.toLowerCase()) ||
    //             comment.role_name.toLowerCase().includes(search.toLowerCase())
    //     );
    // }

    setTotalItems(computedComments.length)

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      computedComments = computedComments.sort(
        (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      )
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [get_product_list, currentPage, search, sorting])

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    if (!productseID) {
      // dispatch(ShoetoggelAction.getusers(data))
      dispatch(adminAction.getProductList({pageNumber: data}))

      return () => {}
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      dispatch(adminAction.getMatchinfoListSearch({key: productseID, page: data}))
      return () => {}
    }
  }

  const hendleSeacrhPro = (e: any) => {
    try {
      // searchProduct
      setproductseID(e)
      dispatch(adminAction.getMatchinfoListSearch({key: e, page: 1}))
      // dispatch(adminAction.getRoleList('dhsg'))
      return () => {}
    } catch (error) {}
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
  }, [itemOffset, comments, itemsPerPage, get_product_list])

  // Table shoorting Function

  const onSortingChange = (field: any) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    setSorting({field, order})
  }

  const hendleChangerole = (e: any) => {
    const {name, value} = e.target
    setroledata({...roledata, [name]: value})
  }

  const [value, setValue] = useState<any>(null)
  const [valueser, setValueser] = useState<any>(null)
  const [valueVenue, setValueVenue] = useState<any>(null)
  const [valueUmapire1, setValueUmapire1] = useState<any>(null)
  const [valueUmapire2, setValueUmapire2] = useState<any>(null)
  const [valueUmapire3, setValueUmapire3] = useState<any>(null)
  const [valueReferee, setValueReferee] = useState<any>(null)
  const [valueTeam1, setValueTeam1] = useState<any>(null)
  const [valueTeam2, setValueTeam2] = useState<any>(null)
  const [valueTeamToss, setValueTeamToss] = useState<any>(null)
  const [valuePlayer, setValuePlayer] = useState<any>(null)

  const [startDate, setStartDate] = useState<any>(null)
  const [startDate2, setStartDate2] = useState<any>(null)

  // setStartDateup()

  const submitProduct = () => {
    // console.log('testSubmit')

    try {
      // const {data: auth} = await login(values.role_name, values.role_description)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData()

      // data.append("title", imageEditfor);

      // images

      // console.log(selectedOption)

      if (!valueser) {
        toast.error('please select serire')
      } else if (!roledata?.match_id) {
        toast.error('please select match id')
      } else if (!roledata?.tv_id) {
        toast.error('please enter tv id ')
      } else if (!roledata?.matchs) {
        toast.error('please enter matchs ')
      } else if (!startDate) {
        toast.error('please select date')
      } else if (!startDate2) {
        toast.error('please select time')
      } else if (!valueVenue) {
        toast.error('please select venue')
      } /*  else if (!valueUmapire1) {
        toast.error('please select  Umapire 1')
      } else if (!valueUmapire2) {
        toast.error('please select  Umapire 2')
      } else if (!valueReferee) {
        toast.error('please select  referee')
      } */ else if (!valueTeam1) {
        toast.error('please select  team 1')
      } else if (!valueTeam2) {
        toast.error('please select  team 2')
      } else {
        setLoading(true)

        data.append('series_id', valueser?.id)
        data.append('match_id', roledata?.match_id)
        data.append('tv_id', roledata?.tv_id)
        data.append('matchs', roledata?.matchs)
        data.append('match_type', roledata?.match_type)
        data.append('match_status', roledata?.match_status)

        data.append('tossdetails', roledata?.tossdetails)

        data.append('match_date', moment(startDate).format('yyyy-MM-DD'))
        data.append('match_time', moment(startDate2).format('h:m A'))
        data.append('venue', valueVenue?.id)
        data.append('toss', valueTeamToss?.team_id)
        data.append('umpire', valueUmapire1?.id)
        data.append('umpire_two', valueUmapire2?.id)
        data.append('third_umpire', valueUmapire3?.id)
        data.append('referee', valueReferee?.id)
        data.append('team_a_id', valueTeam1?.team_id)
        data.append('team_b_id', valueTeam2?.team_id)

        data.append('man_of_match', valuePlayer?.id)

        dispatch(adminAction.createProduct(data))
        setShow(false)
        setLoading(false)
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // console.log(values)

      if (imagesss?.length == 0) {
        // toast.error('please select Image')
      } else {
        setLoading(true)
        try {
          // const {data: auth} = await login(values.role_name, values.role_description)
          // saveAuth(auth)
          // const {data: user} = await getUserByToken(auth.api_token)
          // dispatch(adminAction.createRoles(values))
          const data = new FormData()

          // data.append("title", imageEditfor);

          // images

          data.append('title', values?.role_name)
          data.append('tegs', values?.role_description)
          data.append('description', values?.description)
          data.append('categoryName', 'test cate')
          data.append('categoryId', '11')
          data.append('sellerId', '20')
          data.append('featureImage', images[0]?.file)
          imagesss?.forEach((file: any) => {
            // console.log(file?.file)
            data.append('images', file?.file)
          })
          // console.log(data)

          dispatch(adminAction.createProduct(data))
          setShow(false)
          setLoading(false)

          // setCurrentUser(user)
        } catch (error) {
          // saveAuth(undefined)
          // setStatus('The login details are incorrect')
          // setSubmitting(false)
          // setLoading(false)
        }
      }
    },
  })

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    dispatch(adminAction.getProductList({pageNumber: 1}))
    // dispatch(adminAction.getsubsubcate({}))
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.searchtags({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }, [])

  // create product

  // createProduct

  const hendleCreateProduct = () => {
    const data = new FormData()

    // data.append("title", imageEditfor);

    // images

    imagesss?.forEach((file) => {
      // console.log(file)
    })

    //
  }

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false)
    dispatch(adminAction.deleteProduct({id: productID}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }

  const [imagesfe, setImagesfe] = React.useState<any>([])
  const [imagesproI, setImagesproI] = React.useState<any>([])
  const [editProDetal, seteditProDetal] = React.useState<any>({
    title: '',
    tegs: '',
    productId: '',
    description: '',
  })
  const [selectedOptionUpdate, setSelectedOptionUpdate] = useState<any>(null)
  const [selectedOptionUpdateseller, setSelectedOptionUpdateseller] = useState<any>(null)

  const [selectedOptiontagsup, setSelectedOptiontagsup] = useState<any>(null)
  const [selectedOptiontagsup2, setSelectedOptiontagsup2] = useState<any>(null)

  const [subListUpdate, setsubListUpdate] = React.useState<any>([])
  const onChangefe = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesfe(imageList)
  }

  const onChangeproI = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesproI(imageList)
  }

  const [startDateup, setStartDateup] = useState<any>(null)
  const [startDateup2, setStartDateup2] = useState<any>(null)

  const onChangeDataP = (e: any) => {
    const {name, value} = e.target
    seteditProDetal({...editProDetal, [name]: value})
  }

  const [valueserUp, setValueserUp] = useState<any>(null)
  const [valueVenueUp, setValueVenueUp] = useState<any>(null)
  const [valueUmapire1Up, setValueUmapire1Up] = useState<any>(null)
  const [valueUmapire2Up, setValueUmapire2Up] = useState<any>(null)
  const [valueUmapire3Up, setValueUmapire3Up] = useState<any>(null)
  const [valueRefereeUp, setValueRefereeUp] = useState<any>(null)
  const [valueTeam1Up, setValueTeam1Up] = useState<any>(null)
  const [valueTeam2Up, setValueTeam2Up] = useState<any>(null)
  const [valuePlayerUp, setValuePlayerUp] = useState<any>(null)
  const [valueTeamTossUp, setValueTeamTossUp] = useState<any>(null)
  // const [valueWinTeam, setValueWinTeam] = useState<any>([])

  // console.log(valueWinTeam)

  const hendleeditProductBox = async (e: any) => {
    setShoweditPro(true)

    // getmatchInfoDetails

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    var urlencoded = new URLSearchParams()
    urlencoded.append('id', e.id)

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    }

    //  fetch(
    //       URL.API_BASE_URL + `/apiPublic/getTeamListsearch2?search=${search}&page=${page}`
    //     )
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getmatchInfoDetails`,
      requestOptions
    )

    const responseJSON: any = await response.json()

    const seriresDetatail = responseJSON?.data?.seriesDetails
    seriresDetatail.value = responseJSON?.data?.seriesDetails?.id
    seriresDetatail.label = responseJSON?.data?.seriesDetails?.series

    const firstumpireDetails = responseJSON?.data?.firstumpireDetails
    firstumpireDetails.value = responseJSON?.data?.firstumpireDetails?.id
    firstumpireDetails.label = responseJSON?.data?.firstumpireDetails?.name

    const secondumpireDetails = responseJSON?.data?.secondumpireDetails
    secondumpireDetails.value = responseJSON?.data?.secondumpireDetails?.id
    secondumpireDetails.label = responseJSON?.data?.secondumpireDetails?.name

    const thirdumpireDetails = responseJSON?.data?.thirdumpireDetails
    thirdumpireDetails.value = responseJSON?.data?.thirdumpireDetails?.id
    thirdumpireDetails.label = responseJSON?.data?.thirdumpireDetails?.name

    const refereeDetails = responseJSON?.data?.refereeDetails
    refereeDetails.value = responseJSON?.data?.refereeDetails?.id
    refereeDetails.label = responseJSON?.data?.refereeDetails?.name

    const teamaDetails = responseJSON?.data?.teamaDetails
    teamaDetails.value = responseJSON?.data?.teamaDetails?.id
    teamaDetails.team_id = responseJSON?.data?.teamaDetails?.id
    teamaDetails.label = responseJSON?.data?.teamaDetails?.name

    const teambDetails = responseJSON?.data?.teambDetails
    teambDetails.value = responseJSON?.data?.teambDetails?.id
    teambDetails.team_id = responseJSON?.data?.teambDetails?.id
    teambDetails.label = responseJSON?.data?.teambDetails?.name

    const manofmatchDetails = responseJSON?.data?.manofmatchDetails
    manofmatchDetails.value = responseJSON?.data?.manofmatchDetails?.id
    manofmatchDetails.label = responseJSON?.data?.manofmatchDetails?.name

    const tossDetailsdata = responseJSON?.data?.tossDetailsdata
    tossDetailsdata.value = responseJSON?.data?.tossDetailsdata?.id
    tossDetailsdata.team_id = responseJSON?.data?.tossDetailsdata?.id
    tossDetailsdata.label = responseJSON?.data?.tossDetailsdata?.name

    const venueDetails = responseJSON?.data?.venueDetails
    venueDetails.value = responseJSON?.data?.venueDetails?.id
    venueDetails.label = responseJSON?.data?.venueDetails?.name

    // const teamMarge = [responseJSON?.data?.teamaDetails, responseJSON?.data?.teambDetails]
    // setValueWinTeam(teamMarge)

    // const teamDetatail = e?.teamDetals
    // teamDetatail.value = e?.id
    // teamDetatail.label = e?.teamDetals?.name

    setValueserUp(seriresDetatail)

    setValueVenueUp(venueDetails)

    setValueUmapire1Up(firstumpireDetails)

    setValueUmapire2Up(secondumpireDetails)

    setValueUmapire3Up(thirdumpireDetails)
    setValueRefereeUp(refereeDetails)
    setValueTeam1Up(teamaDetails)
    setValueTeam2Up(teambDetails)
    setValuePlayerUp(manofmatchDetails)
    setValueTeamTossUp(tossDetailsdata)

    if (e?.match_date) {
      // moment
      // console.log(new Date(e?.start_date));
      setStartDateup(new Date(e?.match_date))
      // setStartDateup2
      // start_date
      // end_date

      // console.log(new Date(e?.start_date));
    }

    if (e?.match_time) {
      // moment
      // setStartDateup
      const dateString = e?.match_time
      const [time, meridiem] = dateString.split(' ')
      const [hours, minutes] = time.split(':')

      let date = new Date()
      date.setHours(parseInt(hours))
      date.setMinutes(parseInt(minutes))
      date.setSeconds(0)

      if (meridiem === 'PM' && hours !== '12') {
        date.setHours(date.getHours() + 12)
      }

      setStartDateup2(date)
      // start_date
      // end_date

      // console.log(new Date(e?.end_date));
    }

    seteditProDetal({
      matchs: e?.matchs,
      match_id: e?.match_id,
      productId: e?.id,
      tv_id: e?.tv_id,
      // toss: e?.toss,
      match_type: e?.match_type,
      match_status: e?.match_status,
      tossdetails: e?.tossdetails,
    })
    // subList1

    // setSelectedOptionUpdate(selectCate)
    // setSelectedOptionUpdateseller(selectSeller)
    // setSelectedOptiontagsup(selecttes1)
    // setSelectedOptiontagsup2(selecttes2)

    // const proImg: any =
    //   e?.images &&
    //   e.images?.map((data: any) => {
    //     data.data_url = URL.API_BASE_URL + data.img

    //     return data
    //   })

    // setImagesproI(proImg)
    // setImagesfe([{data_url: URL.API_BASE_URL + e.featureImage}])
  }

  const hendleUpdate = () => {
    const data = new FormData()
    if (!valueserUp) {
      toast.error('please select serire')
    } else if (!editProDetal?.match_id) {
      toast.error('please select match id')
    } else if (!editProDetal?.tv_id) {
      toast.error('please enter tv id ')
    } else if (!editProDetal?.matchs) {
      toast.error('please enter matchs ')
    } else if (!startDateup) {
      toast.error('please select date')
    } else if (!startDateup2) {
      toast.error('please select time')
    } else if (!valueVenueUp) {
      toast.error('please select venue')
    } /* else if (!valueUmapire1Up) {
      toast.error('please select  Umapire 1')
    } else if (!valueUmapire2Up) {
      toast.error('please select  Umapire 2')
    } else if (!valueRefereeUp) {
      toast.error('please select  referee')
    } */ else if (!valueTeam1Up) {
      toast.error('please select  team 1')
    } else if (!valueTeam2Up) {
      toast.error('please select  team 2')
    } else {
      // data.append('title', editProDetal?.title)
      // data.append('tegs', selectedOptiontagsup?.tags)
      // data.append('tegs2', selectedOptiontagsup2?.tags)
      // data.append('categoryName', selectedOptionUpdate?.label)
      // data.append('categoryId', selectedOptionUpdate?._id)
      // data.append('cate_sulg', selectedOptionUpdate?.cate_sulg)
      // data.append('sellerId', selectedOptionUpdateseller?._id)
      // data.append('productId', editProDetal?.productId)
      // data.append('description', editProDetal?.description)

      data.append('match_id', editProDetal?.match_id)
      data.append('tv_id', editProDetal?.tv_id)
      data.append('matchs', editProDetal?.matchs)
      data.append('match_type', editProDetal?.match_type)
      data.append('match_status', editProDetal?.match_status)
      data.append('matchWiinerTeam', editProDetal?.matchWiinerTeam)

      data.append('tossdetails', editProDetal?.tossdetails)

      data.append('match_date', moment(startDateup).format('yyyy-MM-DD'))
      data.append('match_time', moment(startDateup2).format('h:m A'))
      data.append('series_id', valueserUp?.id)
      data.append('venue', valueVenueUp?.id)
      data.append('toss', valueTeamTossUp?.id)
      data.append('umpire', valueUmapire1Up?.id)
      data.append('umpire_two', valueUmapire2Up?.id)
      data.append('third_umpire', valueUmapire3Up?.id)
      data.append('referee', valueRefereeUp?.id)
      data.append('team_a_id', valueTeam1Up?.id)
      data.append('team_b_id', valueTeam2Up?.id)
      data.append('pageNumber', pageNo)
      data.append('man_of_match', valuePlayerUp?.id)
      data.append('id', editProDetal?.productId)

      dispatch(adminAction.updateProduct(data))

      setShoweditPro(false)
    }
  }

  const hendleStatusUpdate = (id: any, status: any) => {
    // statusProduct
    dispatch(adminAction.statusProduct({productId: id, status: status}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }

  const [copyPro, setcopyPro] = useState('')
  const [copyProBoxShoe, setcopyProBoxShoe] = useState(false)

  const closecopyProBoxShoe = () => setcopyProBoxShoe(false)
  const opnecopyProBoxShoe = () => setcopyProBoxShoe(true)

  // copyProduct

  const hendleShowCopyPro = (e: any) => {
    setcopyProBoxShoe(true)
    setcopyPro(e)
  }

  const hendletoCopySubmit = () => {
    // deleteProduct
    setcopyProBoxShoe(false)
    dispatch(adminAction.copyProduct({ProductId: copyPro}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }

  const loadOptions = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL +
        `/apiPublic/getTeamListsearch2serise?search=${search}&page=${page}&series_id=${valueser?.id}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    // const options = responseJSON?.results.map((result: any) => ({
    //   value: result.id,
    //   label: result.name,
    // }))

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.teamDetals?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }
  const loadOptionsUpdate = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL +
        `/apiPublic/getTeamListsearch2serise?search=${search}&page=${page}&series_id=${valueserUp?.id}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    // const options = responseJSON?.results.map((result: any) => ({
    //   value: result.id,
    //   label: result.name,
    // }))

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.teamDetals?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }

  const loadOptionsSerires = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getSeriesInfosListsearch2?search=${search}&page=${page}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.series

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }
  const loadOptionsVenue = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getVenuesListsearch2?search=${search}&page=${page}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }
  const loadOptionsUmpires = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getUmpiresListsearch2?search=${search}&page=${page}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }
  const loadOptionsReferees = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getRefereesListsearch2?search=${search}&page=${page}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }

  const loadOptionsPlayer = async (search: any, loadedOptions: any, {page}: {page: any}) => {
    const response = await fetch(
      URL.API_BASE_URL + `/apiPublic/getPlayersListsearch2?search=${search}&page=${page}`
    )
    const responseJSON: PaginatedResponse = await response.json()

    const options: any = responseJSON?.results?.map((data: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    }
  }

  const [managerKeyMatch, setManagerKeyMatch] = useState<any>(0)
  const [managerKeyTeam, setManagerKeyTeam] = useState<any>(0)
  const [managerKeyPlay, setManagerKeyPlay] = useState<any>(0)

  useEffect(() => {
    // Only load options if a role ID is selected

    if (valueser) {
      loadOptions('', [], {page: 1})

      setManagerKeyTeam((prevKey: any) => prevKey + 1)

      // setValue({})
      setValueTeam2({})
      setValueTeam1({})
      // setValuePlay({})
    }
  }, [valueser])

  return (
    <div>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      {/* <!--begin::Content wrapper-->  */}
      <div className='d-flex flex-column flex-column-fluid'>
        {/* <!--begin::Toolbar-->  */}
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          {/* <!--begin::Toolbar container-->  */}
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Match Info</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select serire</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueser}
                    loadOptions={loadOptionsSerires}
                    onChange={setValueser}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match Id</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a Match id'
                    name='match_id'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a matchs'
                    name='matchs'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select team a</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    key={managerKeyTeam}
                    value={valueTeam1}
                    loadOptions={loadOptions}
                    onChange={setValueTeam1}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select team b</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    key={managerKeyTeam}
                    value={valueTeam2}
                    loadOptions={loadOptions}
                    onChange={setValueTeam2}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Tv Id</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a tv id'
                    name='tv_id'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    // showTimeSelect
                    // timeFormat="HH:mm:ss"
                    className='form-control mt-3'
                    // minDate={new Date()}
                    placeholderText='match date'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match Time</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <DatePicker
                    selected={startDate2}
                    onChange={(date) => setStartDate2(date)}
                    // showTimeSelect
                    // timeFormat="HH:mm:ss"
                    className='form-control mt-3'
                    // minDate={new Date()}
                    placeholderText='match time'
                    // dateFormat='yyyy-MM-dd'
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Venue</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueVenue}
                    loadOptions={loadOptionsVenue}
                    onChange={setValueVenue}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                {/* <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Toss</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a toss'
                    name='toss'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div> */}

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Toss win team</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueTeamToss}
                    loadOptions={loadOptions}
                    onChange={setValueTeamToss}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>select toss </span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='tossdetails'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  >
                    <option value={''}>select match type</option>
                    <option value={'bowl'}>Bowl</option>
                    <option value={'bat'}>Bat</option>
                  </select>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Umpire One</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueUmapire1}
                    loadOptions={loadOptionsUmpires}
                    onChange={setValueUmapire1}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Umpire Two</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueUmapire2}
                    loadOptions={loadOptionsUmpires}
                    onChange={setValueUmapire2}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Umpire Third</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueUmapire3}
                    loadOptions={loadOptionsUmpires}
                    onChange={setValueUmapire3}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Referee</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueReferee}
                    loadOptions={loadOptionsReferees}
                    onChange={setValueReferee}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Man of match</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valuePlayer}
                    loadOptions={loadOptionsPlayer}
                    onChange={setValuePlayer}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Series Teams type</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='match_type'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  >
                    <option value={''}>select match type</option>
                    <option value={'T20'}>T20</option>
                    <option value={'ODI'}>ODI</option>
                    <option value={'T20I'}>T20I</option>
                    <option value={'T10'}>T10</option>
                    <option value={'Test'}>Test</option>
                    <option value={'Hundred'}>Hundred</option>
                  </select>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>match status</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='match_status'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  >
                    <option value={''}>select match status</option>
                    <option value={'upcoming'}>Upcoming</option>
                    <option value={'live'}>Live</option>
                    <option value={'finished'}>Finished</option>
                    {/* <option value={'T10'}>T10</option>
                    <option value={'Test'}>Test</option>
                    <option value={'Hundred'}>Hundred</option> */}
                  </select>
                </div>

                {/* <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Teg</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter teg'
                    name='role_description'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div> */}
                {/* <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Description</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <textarea
                    // className='form-control form-control-solid'
                    placeholder='Enter description'
                    name='description'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Category</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>

                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Seller</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <Select
                    defaultValue={selectedOption1}
                    onChange={setSelectedOption1}
                    options={subList1}
                  />
                 
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Image</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <ImageUploading
                    multiple
                    value={imagesss}
                    onChange={onChangess}
                    maxNumber={maxNumberss}
                    dataURLKey='data_url'
                    acceptType={['jpg']}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className='upload__image-wrapper'>
                        <button
                          className='btn btn-secondary'
                          style={isDragging ? {color: 'red'} : {}}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Choose image
                        </button>
                        &nbsp;
                        <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item'>
                            <img src={image.data_url} alt='' width='100' />
                            <div className='image-item__btn-wrapper'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div> */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={handleClose}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      submitProduct()
                    }}
                    data-kt-permissions-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* update */}
            <Modal show={showeditPro} onHide={handleCloseeditPro}>
              <Modal.Header closeButton>
                <Modal.Title>Update Match Info</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select serire</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueserUp}
                    loadOptions={loadOptionsSerires}
                    onChange={setValueserUp}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match Id</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a Match id'
                    name='match_id'
                    value={editProDetal?.match_id}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select team a</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueTeam1Up}
                    loadOptions={loadOptionsUpdate}
                    onChange={setValueTeam1Up}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select team b</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueTeam2Up}
                    loadOptions={loadOptionsUpdate}
                    onChange={setValueTeam2Up}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Win Team</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='matchWiinerTeam'
                    value={editProDetal?.matchWiinerTeam}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                  >
                    <option value={''}>select win team</option>
                    <option value={'1'}>Team A</option>
                    <option value={'2'}>Team b</option>
                    {/* <option value={'live'}>Live</option>
                    <option value={'finished'}>Finished</option> */}
                  </select>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match </span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a matchs'
                    name='matchs'
                    value={editProDetal?.matchs}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Tv Id</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a tv id'
                    name='tv_id'
                    value={editProDetal?.tv_id}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <DatePicker
                    selected={startDateup}
                    onChange={(date) => setStartDateup(date)}
                    // showTimeSelect
                    // timeFormat="HH:mm:ss"
                    className='form-control mt-3'
                    // minDate={new Date()}
                    placeholderText='match date'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Match Time</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <DatePicker
                    selected={startDateup2}
                    onChange={(date) => setStartDateup2(date)}
                    // showTimeSelect
                    // timeFormat="HH:mm:ss"
                    className='form-control mt-3'
                    // minDate={new Date()}
                    placeholderText='match time'
                    // dateFormat='yyyy-MM-dd'
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Venue</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueVenueUp}
                    loadOptions={loadOptionsVenue}
                    onChange={setValueVenueUp}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                {/* <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Toss</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a toss'
                    name='toss'
                    value={editProDetal?.toss}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div> */}

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Toss win team</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueTeamTossUp}
                    loadOptions={loadOptionsUpdate}
                    onChange={setValueTeamTossUp}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>select toss </span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='tossdetails'
                    value={editProDetal?.tossdetails}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                  >
                    <option value={''}>select match type</option>
                    <option value={'bowl'}>Bowl</option>
                    <option value={'bat'}>Bat</option>
                  </select>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Umpire One</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueUmapire1Up}
                    loadOptions={loadOptionsUmpires}
                    onChange={setValueUmapire1Up}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Umpire Two</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueUmapire2Up}
                    loadOptions={loadOptionsUmpires}
                    onChange={setValueUmapire2Up}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Umpire Third</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueUmapire3Up}
                    loadOptions={loadOptionsUmpires}
                    onChange={setValueUmapire3Up}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Referee</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valueRefereeUp}
                    loadOptions={loadOptionsReferees}
                    onChange={setValueRefereeUp}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Man of match</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <AsyncPaginate<any, any, any>
                    value={valuePlayerUp}
                    loadOptions={loadOptionsPlayer}
                    onChange={setValuePlayerUp}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Series Teams type</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='match_type'
                    value={editProDetal?.match_type}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                  >
                    <option value={''}>select match type</option>
                    <option value={'T20'}>T20</option>
                    <option value={'ODI'}>ODI</option>
                    <option value={'T20I'}>T20I</option>
                    <option value={'T10'}>T10</option>
                    <option value={'Test'}>Test</option>
                    <option value={'Hundred'}>Hundred</option>
                  </select>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>match status</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <select
                    className='form-control form-control-solid'
                    name='match_status'
                    value={editProDetal?.match_status}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
                  >
                    <option value={''}>select match status</option>
                    <option value={'upcoming'}>Upcoming</option>
                    <option value={'live'}>Live</option>
                    <option value={'finished'}>Finished</option>
                  </select>
                </div>

                {/* <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Teg</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                  
                    placeholder='Enter teg'
                    name='tegs'
                    value={editProDetal?.tegs}
                    onChange={(e) => {
                      onChangeDataP(e)
                    }}
              
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div> */}

                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={handleCloseeditPro}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      hendleUpdate()
                    }}
                    data-kt-permissions-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* View modal */}
            <Modal show={addpermission} size='lg' onHide={handleCloseaddpermission}>
              <Modal.Header closeButton>
                <Modal.Title>Match Info</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Woohoo, you're reading this text in a modal! */}

                <div className='mb-10'>
                  <div>
                    {/* <h6>Feature Image</h6>
                    <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div> */}
                    <div className='row'>
                      <div className='col-3'>
                        <h6>Match Name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.matchs}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Match Id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.match_id}</p>
                      </div>
                      <div className='col-3'>
                        <h6>Match Date</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.match_date}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Match Time</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.match_time}</p>
                      </div>
                      <div className='col-3'>
                        <h6>Match Type</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.match_type}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Tv Id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.tv_id}</p>
                      </div>
                      <div className='col-3'>
                        <h6>Maon Of match</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.manofmatchDetails?.name}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>venue </h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.venueDetails?.name}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>match status </h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.match_status}</p>
                      </div>
                    </div>

                    <hr />
                    <h3>Serise</h3>
                    <div className='row'>
                      <div className='col-3'>
                        <h4>Series Details</h4>
                      </div>
                      <div className='col-3'> </div>
                      <div className='col-3'> {/* <h4>Team B</h4> */}</div>
                      <div className='col-3'> </div>

                      <div className='col-3'>
                        <h6>Name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.seriesDetails?.series}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.seriesDetails?.series_id}</p>
                      </div>

                      <div className='col-3'>
                        <h6>short name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.seriesDetails?.series_short}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>short name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.seriesDetails?.short_name}</p>
                      </div>

                      {/* <div className='col-3'>
                        <h6>team id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teamaDetails?.team_id}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>team id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teambDetails?.team_id}</p>
                      </div> */}

                      <div className='col-3'>
                        <h6>banner</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <div style={{width: '150px'}} className='productDetailsImg'>
                          <img
                            style={{width: '100%'}}
                            src={URL.API_BASE_URL + roleID?.seriesDetails?.banner}
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>image</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <div style={{width: '150px'}}>
                          <img
                            style={{width: '100%'}}
                            src={URL.API_BASE_URL + roleID?.seriesDetails?.image}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>

                    <hr />
                    <h3>Teams</h3>
                    <div className='row'>
                      <div className='col-3'>
                        <h4>Team A</h4>
                      </div>
                      <div className='col-3'> </div>
                      <div className='col-3'>
                        {' '}
                        <h4>Team B</h4>
                      </div>
                      <div className='col-3'> </div>

                      <div className='col-3'>
                        <h6>Name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teamaDetails?.name}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teambDetails?.name}</p>
                      </div>

                      <div className='col-3'>
                        <h6>short name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teamaDetails?.short_name}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>short name</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teambDetails?.short_name}</p>
                      </div>

                      <div className='col-3'>
                        <h6>team id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teamaDetails?.team_id}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>team id</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.teambDetails?.team_id}</p>
                      </div>

                      <div className='col-3'>
                        <h6>image</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <div style={{width: '150px'}} className='productDetailsImg'>
                          <img
                            style={{width: '100%'}}
                            src={URL.API_BASE_URL + roleID?.teamaDetails?.flag}
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>image</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <div style={{width: '150px'}}>
                          <img
                            style={{width: '100%'}}
                            src={URL.API_BASE_URL + roleID?.teambDetails?.flag}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h3>Umpires and Referee</h3>
                    <div className='row'>
                      <div className='col-3'>
                        <h6>First Umpires</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.firstumpireDetails?.name}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Second Umpires</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.firstumpireDetails?.name}</p>
                      </div>
                      <div className='col-3'>
                        <h6>Third Umpires</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.thirdumpireDetails?.name}</p>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <h6>Referee</h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{roleID?.refereeDetails?.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* delete modal  */}
            <Modal show={showDelelt} onHide={handleCloseDelete}>
              <Modal.Header closeButton>
                <Modal.Title> Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure
                {/* <div className='mb-10 d-flex'>
                  <div>
                    <h6>Feature Image</h6>
                    <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div>
                    <div>
                      <h6>Product Name</h6>
                      <p>{roleID?.title}</p>
                      <h6>Teg</h6>
                      <p>{roleID?.tegs}</p>
                    </div>
                    <h6>product Image</h6>
                    <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseDelete}>
                  No
                </Button>
                <Button variant='primary' onClick={hendleDelete}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            {/*  copy product */}
            <Modal show={copyProBoxShoe} onHide={closecopyProBoxShoe}>
              <Modal.Header closeButton>
                <Modal.Title> Copy Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={closecopyProBoxShoe}>
                  No
                </Button>
                <Button variant='primary' onClick={hendletoCopySubmit}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <!--begin::Page title-->  */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              {/* <!--begin::Title-->  */}
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                Match Info
              </h1>
              {/* <!--end::Title-->  */}
              {/* <!--begin::Breadcrumb-->  */}
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>
                  {/* <a href='../../demo1/dist/index.html' className='text-muted text-hover-primary'>
                    Home
                  </a> */}
                  <Link to={'/'} className='text-muted text-hover-primary'>
                    Home
                  </Link>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>Macth Info </li>
                {/* <!--end::Item-->  */}
              </ul>
              {/* <!--end::Breadcrumb-->  */}
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              {/* <!--begin::Filter menu-->  */}
              <div className='m-0'>
                {/* <!--begin::Menu toggle-->  */}

                {/* <!--end::Menu toggle-->  */}
                {/* <!--begin::Menu 1-->  */}
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                  id='kt_menu_637dc7729cedd'
                >
                  {/* <!--begin::Header-->  */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                  </div>
                  {/* <!--end::Header-->  */}
                  {/* <!--begin::Menu separator-->  */}
                  <div className='separator border-gray-200'></div>
                  {/* <!--end::Menu separator-->  */}
                  {/* <!--begin::Form-->  */}
                  <div className='px-7 py-5'>
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Status:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Input-->  */}
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-dropdown-parent='#kt_menu_637dc7729cedd'
                          data-allow-clear='true'
                        >
                          <option></option>
                          <option value='1'>Approved</option>
                          <option value='2'>Pending</option>
                          <option value='2'>In Process</option>
                          <option value='2'>Rejected</option>
                        </select>
                      </div>
                      {/* <!--end::Input-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Member Type:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Options-->  */}
                      <div className='d-flex'>
                        {/* <!--begin::Options-->  */}
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input className='form-check-input' type='checkbox' value='1' />
                          <span className='form-check-label'>Author</span>
                        </label>
                        {/* <!--end::Options-->  */}
                        {/* <!--begin::Options-->  */}
                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='2'
                            //   checked='checked'
                          />
                          <span className='form-check-label'>Customer</span>
                        </label>
                        {/* <!--end::Options-->  */}
                      </div>
                      {/* <!--end::Options-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Notifications:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Switch-->  */}
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='notifications'
                          // checked='checked'
                        />
                        <label className='form-check-label'>Enabled</label>
                      </div>
                      {/* <!--end::Switch-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Actions-->  */}
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                      >
                        Reset
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        Apply
                      </button>
                    </div>
                    {/* <!--end::Actions-->  */}
                  </div>
                  {/* <!--end::Form-->  */}
                </div>
                {/* <!--end::Menu 1-->  */}
              </div>
              {/* <!--end::Filter menu-->  */}
              {/* <!--begin::Secondary button-->  */}
              {/* <!--end::Secondary button-->  */}
              {/* <!--begin::Primary button-->  */}

              {/* <!--end::Primary button-->  */}
            </div>
            {/* <!--end::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>
        {/* <!--end::Toolbar-->  */}
        {/* <!--begin::Content-->  */}
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          {/* <!--begin::Content container-->  */}
          <div id='kt_app_content_container' className='app-container container-xxl'>
            {/* <!--begin::Card-->  */}
            <div className='card card-flush'>
              {/* <!--begin::Card header-->  */}
              <div className='card-header mt-6'>
                {/* <!--begin::Card title-->  */}
                <div className='card-title'>
                  {/* <!--begin::Search-->  */}
                  <div className='d-flex align-items-center position-relative my-1 me-5'>
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->  */}
                    <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='currentColor'
                        />
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon-->  */}
                    <input
                      type='text'
                      data-kt-permissions-table-filter='search'
                      className='form-control form-control-solid w-250px ps-15'
                      placeholder='Search Match'
                      onChange={(e: any) => {
                        hendleSeacrhPro(e.target.value)
                      }}
                    />
                  </div>
                  {/* <!--end::Search-->  */}
                </div>
                {/* <!--end::Card title-->  */}
                {/* <!--begin::Card toolbar-->  */}
                <div className='card-toolbar'>
                  {/* <!--begin::Button-->  */}
                  <button
                    type='button'
                    className='btn btn-light-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_add_permission'
                    onClick={handleShow}
                  >
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->  */}
                    <span className='svg-icon svg-icon-3'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.3'
                          x='2'
                          y='2'
                          width='20'
                          height='20'
                          rx='5'
                          fill='currentColor'
                        />
                        <rect
                          x='10.8891'
                          y='17.8033'
                          width='12'
                          height='2'
                          rx='1'
                          transform='rotate(-90 10.8891 17.8033)'
                          fill='currentColor'
                        />
                        <rect
                          x='6.01041'
                          y='10.9247'
                          width='12'
                          height='2'
                          rx='1'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon-->  */}Add Match Info
                  </button>
                  {/* <!--end::Button-->  */}
                  {/* <Button variant='primary' onClick={handleShow}>
                  Launch demo modal
                </Button> */}
                </div>
                {/* <!--end::Card toolbar-->  */}
              </div>
              {/* <!--end::Card header-->  */}
              {/* <!--begin::Card body-->  */}
              <div className='card-body pt-0'>
                {/* <!--begin::Table-->  */}
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                  id='kt_permissions_table'
                >
                  {/* <!--begin::Table head-->  */}
                  <thead>
                    <tr>
                      {headers.map((data: any, i: any) => (
                        <th
                          key={data?.name}
                          onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                        >
                          {data?.name}

                          {sortingField &&
                            sortingField === data?.field &&
                            (sortingOrder === 'asc' ? (
                              //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                              <i className='fa-solid fa-arrow-down'></i>
                            ) : (
                              <i className='fas fa-arrow-alt-up'></i>
                            ))}
                        </th>
                      ))}
                    </tr>

                    {/* <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) => setSorting({field, order})}
                  /> */}
                    {/* <!--end::Table row-->  */}
                  </thead>
                  {/* <!--end::Table head-->  */}
                  {/* <!--begin::Table body-->  */}
                  <tbody className='fw-semibold text-gray-600'>
                    {commentsData?.map((data: any, i: any) => {
                      const So = 1 + i

                      const pgt1 = pageNo - 1

                      const pgt = pgt1 * 10

                      const soNo = pgt + So
                      return (
                        <tr>
                          {/* <!--begin::Name=-->  */}
                          {/* <td>User Management</td> */}
                          {/* <!--end::Name=-->  */}
                          {/* <!--begin::Assigned to=-->  */}
                          <td>
                            {soNo}
                            {/* <a
                            href='../../demo1/dist/apps/user-management/roles/view.html'
                            className='badge badge-light-primary fs-7 m-1'
                          >
                            Administrator
                          </a> */}
                          </td>
                          {/* <!--end::Assigned to=-->  */}
                          {/* <!--begin::Created Date-->  */}
                          <td>{data?.match_id}</td>
                          <td>{data?.matchs}</td>
                          <td>{data?.toss}</td>
                          {/* <td>
                          
                            <button
                              data-id='2209'
                              onClick={() => {
                                const statusValue = data?.status == 'Active' ? 'Inactive' : 'Active'
                                hendleStatusUpdate(data?._id, statusValue)
                              }}
                              className={
                                data?.status == 'Active'
                                  ? 'btn btn-sm btn-success viewItem'
                                  : 'btn btn-sm btn-danger viewItem'
                              }
                            >
                              {data?.status}
                            </button>
                          </td> */}
                          {/* <td>{data?.delete ? 'Yes' : 'No'}</td> */}
                          {/* <!--end::Created Date-->  */}
                          {/* <!--begin::Action=-->  */}
                          <td>
                            {/* <!--begin::Update-->  */}

                            {/* <td className="text-end"> */}

                            {/* <Dropdown>
                              <Dropdown.Toggle
                                variant='btn btn-light btn-active-light-primary btn-sm'
                                id='dropdown-basic'
                              >
                                Action
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                     
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleShowCopyPro(data?._id)
                                  }}
                                >
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleToaddPer(data)
                                  }}
                                >
                                  View
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleeditProductBox(data)
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleModalShow(data?._id)
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}

                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleeditProductBox(data)
                              }}
                              className='btn btn-sm btn-info ms-3'
                            >
                              <i className='fas fa-edit'></i>
                            </button>

                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleToaddPer(data?.id)
                              }}
                              className='btn btn-sm btn-info viewItem ms-3'
                            >
                              <i className='fas fa-eye'></i>
                            </button>
                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleModalShow(data?.id)
                              }}
                              className='btn btn-sm btn-danger ms-3'
                            >
                              <i className='fas fa-trash-alt'></i>
                            </button>
                            {/* <button
                              data-id='2209'
                              onClick={() => {
                                hendleShowCopyPro(data?._id)
                              }}
                              className='btn btn-sm btn-primary ms-3'
                            >
                              <i className='fas fa-trash-alt'></i>
                              Copy
                            </button> */}

                            {/* <!--end::Menu--> */}
                            {/* </td> */}
                            {/* <button
                              className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_update_permission'
                            >
                             
                              <span className='svg-icon svg-icon-3'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.3'
                                    d='M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            
                            </button> */}

                            {/* <!--end::Update-->  */}
                            {/* <!--begin::Delete-->  */}
                            {/* <button
                              className='btn btn-icon btn-active-light-primary w-30px h-30px'
                              data-kt-permissions-table-filter='delete_row'
                            >
                            
                              <span className='svg-icon svg-icon-3'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.5'
                                    d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.5'
                                    d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                           
                            </button> */}
                            {/* <!--end::Delete-->  */}
                          </td>
                          {/* <!--end::Action=-->  */}
                        </tr>
                      )
                    })}
                  </tbody>
                  {get_product_list?.total == '0' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <h3>Record Not found</h3>
                    </div>
                  )}
                  {/* <!--end::Table body-->  */}
                </table>

                <ReactPaginate
                  breakLabel='...'
                  nextLabel='next'
                  className='dataTables_paginate paging_simple_numbers category'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel='previous'
                  // renderOnZeroPageCount={null}
                />
              </div>

              {/* <!--end::Card body-->  */}
            </div>
            {/* <!--end::Card-->  */}
            {/* <!--begin::Modals-->  */}
            {/* <!--begin::Modal - Add permissions-->  */}
            <div
              className='modal fade'
              id='kt_modal_add_permission'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/* <!--begin::Modal dialog-->  */}
              <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* <!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/* <!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/* <!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Add a Permission</h2>
                    {/* <!--end::Modal title-->  */}
                    {/* <!--begin::Close-->  */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-permissions-modal-action='close'
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                    </div>
                    {/* <!--end::Close-->  */}
                  </div>
                  {/* <!--end::Modal header-->  */}
                  {/* <!--begin::Modal body-->  */}
                  <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                    {/* <!--begin::Form-->  */}
                    <form id='kt_modal_add_permission_form' className='form' action='#'>
                      {/* <!--begin::Input group-->  */}
                      <div className='fv-row mb-7'>
                        {/* <!--begin::Label-->  */}
                        <label className='fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Permission Name</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='popover'
                            data-bs-trigger='hover'
                            data-bs-html='true'
                            data-bs-content='Permission names is required to be unique.'
                          ></i>
                        </label>
                        {/* <!--end::Label-->  */}
                        {/* <!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter a permission name'
                          name='permission_name'
                        />
                        {/* <!--end::Input-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Input group-->  */}
                      <div className='fv-row mb-7'>
                        {/* <!--begin::Checkbox-->  */}
                        <label className='form-check form-check-custom form-check-solid me-9'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            name='permissions_core'
                            id='kt_permissions_core'
                          />
                          <span className='form-check-label' /* for='kt_permissions_core' */>
                            Set as core permission
                          </span>
                        </label>
                        {/* <!--end::Checkbox-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Disclaimer-->  */}
                      <div className='text-gray-600'>
                        Permission set as a<strong className='me-1'>Core Permission</strong>will be
                        locked and
                        <strong className='me-1'>not editable</strong>in future
                      </div>
                      {/* <!--end::Disclaimer-->  */}
                      {/* <!--begin::Actions-->  */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          className='btn btn-light me-3'
                          data-kt-permissions-modal-action='cancel'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-permissions-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      </div>
                      {/* <!--end::Actions-->  */}
                    </form>
                    {/* <!--end::Form-->  */}
                  </div>
                  {/* <!--end::Modal body-->  */}
                </div>
                {/* <!--end::Modal content-->  */}
              </div>
              {/* <!--end::Modal dialog-->  */}
            </div>
            {/* <!--end::Modal - Add permissions-->  */}
            {/* <!--begin::Modal - Update permissions-->  */}
            <div
              className='modal fade'
              id='kt_modal_update_permission'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/* <!--begin::Modal dialog-->  */}
              <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* <!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/* <!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/* <!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Update Permission</h2>
                    {/* <!--end::Modal title-->  */}
                    {/* <!--begin::Close-->  */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-permissions-modal-action='close'
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                    </div>
                    {/* <!--end::Close-->  */}
                  </div>
                  {/* <!--end::Modal header-->  */}
                  {/* <!--begin::Modal body-->  */}
                  <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                    {/* <!--begin::Notice-->  */}
                    {/* <!--begin::Notice-->  */}
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                      {/* <!--begin::Icon-->  */}
                      {/* <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->  */}
                      <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x='2'
                            y='2'
                            width='20'
                            height='20'
                            rx='10'
                            fill='currentColor'
                          />
                          <rect
                            x='11'
                            y='14'
                            width='7'
                            height='2'
                            rx='1'
                            transform='rotate(-90 11 14)'
                            fill='currentColor'
                          />
                          <rect
                            x='11'
                            y='17'
                            width='2'
                            height='2'
                            rx='1'
                            transform='rotate(-90 11 17)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                      {/* <!--end::Icon-->  */}
                      {/* <!--begin::Wrapper-->  */}
                      <div className='d-flex flex-stack flex-grow-1'>
                        {/* <!--begin::Content-->  */}
                        <div className='fw-semibold'>
                          <div className='fs-6 text-gray-700'>
                            <strong className='me-1'>Warning!</strong>By editing the permission
                            name, you might break the system permissions functionality. Please
                            ensure you're absolutely certain before proceeding.
                          </div>
                        </div>
                        {/* <!--end::Content-->  */}
                      </div>
                      {/* <!--end::Wrapper-->  */}
                    </div>
                    {/* <!--end::Notice-->  */}
                    {/* <!--end::Notice-->  */}
                    {/* <!--begin::Form-->  */}
                    <form id='kt_modal_update_permission_form' className='form' action='#'>
                      {/* <!--begin::Input group-->  */}
                      <div className='fv-row mb-7'>
                        {/* <!--begin::Label-->  */}
                        <label className='fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Permission Name</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='popover'
                            data-bs-trigger='hover'
                            data-bs-html='true'
                            data-bs-content='Permission names is required to be unique.'
                          ></i>
                        </label>
                        {/* <!--end::Label-->  */}
                        {/* <!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter a permission name'
                          name='permission_name'
                        />
                        {/* <!--end::Input-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Actions-->  */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          className='btn btn-light me-3'
                          data-kt-permissions-modal-action='cancel'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-permissions-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      </div>
                      {/* <!--end::Actions-->  */}
                    </form>
                    {/* <!--end::Form-->  */}
                  </div>
                  {/* <!--end::Modal body-->  */}
                </div>
                {/* <!--end::Modal content-->  */}
              </div>
              {/* <!--end::Modal dialog-->  */}
            </div>
            {/* <!--end::Modal - Update permissions-->  */}
            {/* <!--end::Modals-->  */}
          </div>
          {/* <!--end::Content container-->  */}
        </div>
        {/* <!--end::Content-->  */}
      </div>
      {/* <!--end::Content wrapper-->  */}
      {/* <!--begin::Footer-->  */}

      {/* <!--end::Footer-->  */}
      {/* </div> */}
    </div>
  )
}

export default Product
