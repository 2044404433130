import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TestPage from '../pages/dashboard/TestPage'
import PermissionPage from '../pages/Permission/Permission'
import Permisiion2 from '../pages/Permission/Permisiion2'
import Role from '../pages/Role/Role'
import Product from '../pages/Product/Product'
import Category from '../pages/Category/Category'
import Seller from '../pages/Seller/Seller'
import SubCategory from '../pages/Category/SubCategory'
import SubSubCategory from '../pages/Category/SubSubCategory'
import Package from '../pages/Package/Package'
import Transaction from '../pages/Transaction/Transaction'
import Ticket from '../pages/Ticket/Ticket'
import PageList from '../pages/PageList/PageList'
import WhatsappInquiry from '../pages/Inquiry/WhatsappInquiry'
import EmailInquiry from '../pages/Inquiry/EmailInquiry'
import Contacts from '../pages/Inquiry/Contacts'
import Profile from '../pages/Profile/Profile'
import Setting from '../pages/Setting/Setting'
import Tags from '../pages/Tags/Tags'
import Blog from '../pages/Product/Blog'
import Faqs from '../pages/Faqs/Faqs'
import Batsman from '../pages/Transaction/Batsman'
import Playeleven from '../pages/Transaction/Playeleven'
import Bolwer from '../pages/Transaction/Bolwer'
import Bolwer2 from '../pages/Transaction/Bolwer2'
import PointsTable from '../pages/Transaction/PointsTable'
import Fallwickets from '../pages/Transaction/Fallwickets'

import NativeSponser from '../pages/Sponser/nativeSponser'
import BannerSponser from '../pages/BannerSponser/Sponser'
import InitrialSponser from '../pages/InitrialSponser/Sponser'
import Message from '../pages/Message/Message'
import Help from '../pages/help/Help'
import News from '../pages/News/News'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='permission' element={<PermissionPage />} />
        <Route path='role' element={<Role />} />
        <Route path='match-info' element={<Product />} />
        <Route path='player' element={<Category />} />
        <Route path='users' element={<Seller />} />
        <Route path='teams' element={<SubCategory />} />
        <Route path='referee' element={<Package />} />
        <Route path='umpires' element={<Faqs />} />
        <Route path='venue' element={<Ticket />} />
        <Route path='series-teams' element={<Blog />} />
        <Route path='series-info' element={<SubSubCategory />} />
        <Route path='match-squad' element={<Transaction />} />
        <Route path='batsman' element={<Batsman />} />
        <Route path='bolwer' element={<Bolwer2 />} />
        <Route path='points-table' element={<PointsTable />} />
        <Route path='playeleven' element={<Playeleven />} />
        <Route path='whatsapp-inquiry' element={<WhatsappInquiry />} />
        <Route path='email-inquiry' element={<EmailInquiry />} />
        <Route path='contacts' element={<Contacts />} />
        <Route path='tags' element={<Tags />} />
        <Route path='pages' element={<PageList />} />
        <Route path='profile' element={<Profile />} />
        <Route path='settings' element={<Setting />} />
        <Route path='fallwickets' element={<Fallwickets />} />
        <Route path='native-sponser' element={<NativeSponser/>}/>
        <Route path='banner-sponser' element={<BannerSponser/>}/>
        <Route path='initrial-sponser' element={<InitrialSponser/>}/>
		    <Route path='message' element={<Message/>}/>
        <Route path='help' element={<Help/>}/>
        <Route path='news' element={<News/>}/>
        {/* <Route path='permission/*' element={<Permisiion2 />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
